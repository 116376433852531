import UtilService from "../Services/UtilService"
import AppSpecific from '../AppSpecific';

import cloneDeep from 'lodash/cloneDeep';

class PageDataService {


    constructor() {
        this.isLoaded = false;
        this.changeEvent = [];
        this.rowIds = 0;
        this.clear();
    }

    clear() {

        this.sportAngebotDescriptions = {};
        this.abteilungsSeiten = {};
        this.ueberUnsSeiten = {};
        this.jobSeiten = [];
        this.jobSeitenHash = {};
        this.vgymSeiten = {};
        this.veranstaltungsSeiten = [];
        this.veranstaltungsSeitenHash = {};
        this.sportStaettenDescriptions = {};
        this.specialPageDescriptions = {};
        this.pageByIdHash = {};
    }

    replaceTitle(title) {

        title = title.replaceAll('&#038;', '&');
        return title;
    }

    updateFromData(pageData) {
        this.clear();
        this.isLoaded = true;
        let that = this;


        var allowAllStates = AppSpecific.allowAllStates;
        var pageDataHash = {};
        var realPageData = [];
        pageData.forEach(function (entry) {
            if (allowAllStates || entry.status === 'publish') {
                let realEntry = cloneDeep(entry);

                if (realEntry.content && realEntry.content.rendered) {
                    realEntry.content.rendered = realEntry.content.rendered.replaceAll('_AND_', '&');
                }
                if (pageDataHash[realEntry.id]) {
                    console.error("duplicate", pageDataHash[realEntry.id], realEntry);
                }
                pageDataHash[realEntry.id] = realEntry;
                realEntry.children = [];
                realPageData.push(realEntry)
                console.log("added page id",realEntry.id);
            } else {
                console.log("not using because of entry state", entry);
            }
        });

        var refOb = [];
        realPageData.forEach((entry) => {
            if (allowAllStates || entry.status === 'publish') {
                let parent = pageDataHash[entry.parent];
                if (!entry.$parentSet) {
                    entry.$parentSet = true;

                    if (entry.status !== "publish") {
                        entry.title.rendered += '-(' + entry.status.toUpperCase() + ')';
                        entry.content.rendered = '(' + entry.status.toUpperCase() + ') <br/>' + entry.content.rendered;
                        if (!entry.slug) {
                            entry.slug = "draft_" + entry.id + "";
                        }
                    }
                    if (parent) {
                        entry.parentOb = parent;
                        entry.parentOb.children.push(entry);
                        entry.parentOb.ref = refOb;
                        refOb.push(entry);
                    } else if (entry.parent !== 0) {
                        console.error("no parent", entry.parent, entry);
                    }
                }
            }
        });
        let sportangebotBeschreibungen = pageDataHash[20];
        let spezialSeiten = pageDataHash[94];
        let abteilungsSeiten = pageDataHash[129];
        let ueberUnsSeiten = pageDataHash[94];
        let sportStaettenDescriptions = pageDataHash[79];
        let jobSeiten = pageDataHash[202];
        let virtualGymPages = pageDataHash[2445];
        let veranstaltungsSeiten = pageDataHash[1262];
        let mainPageTop = pageDataHash[2131];

        let newsBilder = pageDataHash[492];

        console.log("virtualGymPages", virtualGymPages);

        if (newsBilder) {

            let newsArray = [];
            let newsIds = 0;
            newsBilder.children.forEach((entry) => {
                if (entry.content && entry.content.rendered) {

                    let srcIdx = entry.content.rendered.indexOf('src="');
                    if (srcIdx !== -1) {
                        let imgSrc = entry.content.rendered.substr(srcIdx + 5, entry.content.rendered.length);
                        imgSrc = imgSrc.substr(0, imgSrc.indexOf("\""));
                        if (imgSrc) {
                            let newsEntry = {
                                id: newsIds++,
                                title: entry.title.rendered,
                                newsOrder: (100 - newsIds) + (entry.menu_order) * 100,
                                img: imgSrc

                            };
                            newsArray.push(newsEntry);
                            newsArray.sort((a, b) => {
                                return a.newsOrder - b.newsOrder;
                            });
                        }
                    }
                }
            });
            that.newsArray = newsArray;
        } else {
            that.newsArray = [];
        }
        if (sportangebotBeschreibungen)
            sportangebotBeschreibungen.children.forEach((entry) => {
                let name = UtilService.convertToSlugString(entry.slug);
                that.sportAngebotDescriptions[name] = entry;
            });
        sportStaettenDescriptions.children.forEach((entry) => {
            let name = UtilService.convertToSlugString(entry.slug);
            that.sportStaettenDescriptions[name] = entry;
        });
        if (spezialSeiten)
            spezialSeiten.children.forEach((entry) => {
                let name = UtilService.convertToSlugString(entry.slug);
                that.specialPageDescriptions[name] = entry;
            });

        console.log("getSpecialPageDescription", that.specialPageDescriptions);
        let callRecursiveForApt = (type, abteilungsSeitenName, entry, originalAbt) => {
            let abt = originalAbt;
            let abtSplitted = abt.split('/');
            let splitEles = [];
            abtSplitted.forEach((entry) => {
                if (entry.length > 0) {
                    splitEles.push(entry);
                }
            });
            if (splitEles.length >= 1) {


                abt = splitEles[0];

                if (!that[abteilungsSeitenName][abt]) {
                    that[abteilungsSeitenName][abt] = {
                        '_menu': {}
                    };
                }
                let menu = that[abteilungsSeitenName][abt]['_menu'];

                var menuLink = menu;
                var fullSlug = splitEles[0];
                let path = '/' + type + '/' + splitEles[0];
                for (let m = 1; m < splitEles.length; m++) {

                    if (!menuLink[splitEles[m]]) {
                        menuLink[splitEles[m]] = {};
                    }
                    menuLink = menuLink[splitEles[m]];
                    fullSlug += '-' + splitEles[m];
                    path += '/' + splitEles[m];
                }
                //entry.slug = UtilService.convertToSlugString(fullSlug);
                entry.slug = fullSlug;
                menuLink['_link'] = UtilService.convertToSlugString(entry.slug);
                menuLink['_title'] = that.replaceTitle(entry.title.rendered);
                //console.log("stuffyboy",entry);
                menuLink['_order'] = entry.menu_order;

                if (splitEles.length === 1) {
                    path += '/index';
                }
                path = '/' + type + '/' + splitEles[0] + '/' + UtilService.convertToSlugString(entry.slug);


                menuLink['_path'] = path;
                let name = UtilService.convertToSlugString(entry.slug);
                //console.log("abteilungsseite1 "+abt+" "+name+" p "+path,entry,splitEles,menu,that.abteilungsSeiten,entry);
                that[abteilungsSeitenName][abt][name] = entry;

            } else {
                // console.error("abteilungsseite1 weird page",abt,entry);
            }

            entry.children.forEach((child) => {
                callRecursiveForApt(type, abteilungsSeitenName, child, originalAbt + '/' + child.title.rendered.toLowerCase());
            })


        };
        if (abteilungsSeiten) {
            abteilungsSeiten.children.forEach((entry) => {

                callRecursiveForApt('abteilung', 'abteilungsSeiten', entry, entry.title.rendered.toLowerCase());

            });
        }
        if(ueberUnsSeiten){
            ueberUnsSeiten.children.forEach((entry) => {

                callRecursiveForApt('ueberUns', 'ueberUnsSeiten', entry, entry.title.rendered.toLowerCase());

            });
        }
        if (virtualGymPages) {
            virtualGymPages.children.forEach((entry) => {

                callRecursiveForApt('virtualgym', 'vgymSeiten', entry, entry.title.rendered.toLowerCase());
            });
            console.log("vgymSeiten", this.vgymSeiten);
        }
        if (jobSeiten) {
            this.jobSeiten = [];
            this.jobSeitenHash = {};
            this.jobsSeitenList = [];

            jobSeiten.children.forEach((entry) => {
                let id = UtilService.convertToSlugString(entry.slug);
                this.jobSeitenHash[id] = entry;
                this.jobSeiten.push(entry);

                let jobEntry = {
                    id: id,
                    title: entry.title.rendered,
                    link: "jobs/" + id
                };
                this.jobsSeitenList.push(jobEntry);

            });
        }
        if (veranstaltungsSeiten) {
            this.veranstaltungsSeiten = [];
            this.veranstaltungsSeitenHash = {};
            this.veranstaltungsSeitenList = [];

            veranstaltungsSeiten.children.forEach((entry) => {
                let id = UtilService.convertToSlugString(entry.slug);
                this.veranstaltungsSeitenHash[id] = entry;
                this.veranstaltungsSeiten.push(entry);

                let veranstaltungsEntry = {
                    id: id,
                    title: entry.title.rendered,
                    link: "veranstaltungen/" + id
                };
                this.veranstaltungsSeitenList.push(veranstaltungsEntry);

            });
        }
        this.mainPageData = {
            html: '<div></div>'
        };
        if (mainPageTop) {
            this.mainPageData.html = mainPageTop.content.rendered;
        }


        this.pageByIdHash = pageDataHash;
        this.changeEvent.forEach((ele) => {
            ele();
        });
    }

    getMainPageContent() {
        return this.mainPageData.html;
    }

    getNews() {
        if (!this.newsArray) {
            this.newsArray = [];
        }
        return this.newsArray;
    }

    getPageById(id) {
        return this.pageByIdHash[id];
    }

    getJobsList() {
        return this.jobsSeitenList;
    }

    getVeranstaltungsList() {
        return this.veranstaltungsSeitenList;
    }

    getSpecialPageDescription(name) {
        let slug = UtilService.convertToSlugString(name);

        if (this.specialPageDescriptions[slug]) {
            return this.specialPageDescriptions[slug].content.rendered;
        } else {
            return null;
        }
    }


    getSportAngebotDescription(name) {
        let slug = UtilService.convertToSlugString(name);

        if (this.sportAngebotDescriptions[slug]) {
            return this.sportAngebotDescriptions[slug].content.rendered;
        } else {
            return null;
        }
    }

    getJobPage(slug) {
        slug = UtilService.convertToSlugString(slug);
        if (this.jobSeitenHash[slug]) {
            return this.jobSeitenHash[slug].content.rendered;
        } else {
            return null;
        }
    }

    getVeranstaltungPage(slug) {
        slug = UtilService.convertToSlugString(slug);
        if (this.veranstaltungsSeitenHash[slug]) {
            return this.veranstaltungsSeitenHash[slug].content.rendered;
        } else {
            return null;
        }
    }

    getSportStaettenDescription(name) {
        let slug = UtilService.convertToSlugString(name);

        if (this.sportStaettenDescriptions[slug]) {
            return this.sportStaettenDescriptions[slug].content.rendered;
        } else {
            return null;
        }
    }

    getAbteilungsSeite(abt, name) {

        let slug = UtilService.convertToSlugString(name);

        if (this.abteilungsSeiten[abt][slug]) {
            return this.abteilungsSeiten[abt][slug].content.rendered;
        } else {
            return null;
        }
    }
    getUeberUnsSeite(abt, name) {

        let slug = UtilService.convertToSlugString(name);

        if (this.ueberUnsSeiten[abt][slug]) {
            return this.ueberUnsSeiten[abt][slug].content.rendered;
        } else {
            return null;
        }
    }

    getVGymSeite(abt, name) {

        let slug = UtilService.convertToSlugString(name);

        if (this.vgymSeiten[abt][slug]) {
            return this.vgymSeiten[abt][slug].content.rendered;
        } else {
            return null;
        }
    }

    createRecursiveMenu(abtMenu) {

        let e = {};

        Object.keys(abtMenu).forEach((key) => {
            let v = abtMenu[key];

            if (key.indexOf("_") !== 0) {

                if (!e._children) {
                    e._children = [];
                }
                e._children.push(this.createRecursiveMenu(v));
            } else {
                e[key] = v;
            }

        });


        return e;
    }

    getAbteilungsMenu(abt) {

        if (this.abteilungsSeiten[abt]) {
            let menu = this.createRecursiveMenu(this.abteilungsSeiten[abt]._menu);

            return menu;
        } else {
            return null;
        }
    }
    getUeberUnsMenu(abt) {

        if (this.ueberUnsSeiten[abt]) {
            let menu = this.createRecursiveMenu(this.ueberUnsSeiten[abt]._menu);

            return menu;
        } else {
            return null;
        }
    }

    onChange(func) {
        var found = false;
        this.changeEvent.forEach((ele) => {
            if (ele === func) {
                found = true;
            }
        });
        if (!found) {
            this.changeEvent.push(func);
        }
        if (this.isLoaded) {
            func();
        }
    };


    removeOnChange(func) {
        let idx = this.changeEvent.indexOf(func);
        if (idx >= 0) {
            this.changeEvent.splice(idx, 1);
        }
    }

}

export default new PageDataService();
