import React from "react";
import SportService from "../DataSources/SportService"
import StdContentDisplay from "./StdContentDisplay"


export default class SportprogrammDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            sportAngebot: {},
            sportcenters: [],
            categories: [],
            termine: [],
            angebote: []
        };

    }

    componentDidMount() {
        this.state.description = null;
        SportService.onChange(() => {
            this.setState(this.state);

            setTimeout(()=>{
                this.state.termine = SportService.findTerminByFullQueryAndSetCurrentFilter({});

                this.state.sportcenters = SportService.findRelatedSportCentersForTermine(this.state.termine);

                this.state.categories = SportService.categorizeByAngebot(this.state.termine);

                this.state.angebote = SportService.findSportAngeboteByTermine(this.state.termine);
                this.state.loading= false;
                this.setState(this.state);
            },1);

        });
    }

    render() {
        return (
            <StdContentDisplay description={this.state.description} name="Komplettes Sportprogramm"
                               header="Komplettes Sportprogramm"
                               image=""
                               loading ={this.state.loading}
                               categories={this.state.categories}
                               lists={[{
                                   baseLink: "/angebot/",
                                   header: 'Angebote',
                                   list: this.state.angebote
                               }, {
                                   baseLink: "/sportcenter/",
                                   header: 'Orte',
                                   list: this.state.sportcenters
                               }]}
            />
        )
    }
}