import React from "react";
import Slider from "react-slick/lib";
import SImgWithShadow from "./SImgWithShadow";
import MainFlowManager from "../Services/MainFlowManager";
import SImageWithText from "./SImageWithText";

export default class SImageShowSlider extends React.Component {

    render() {

        if(window.isMobile){
            return (

                <div className="mobile-slider">
                    {
                        this.props.images.map((ob) =>
                            <SImgWithShadow key={ob.img} width={this.props.itemWidth} src={ob.img} onClick={()=>{
                                this.props.onImgClick(ob);
                            }}>
                            </SImgWithShadow>
                        )
                    }
                </div>
            );
        }
        return (

            <>

                <Slider style={{marginLeft: 10, marginRight: 10}} {...{
                    slidesToShow: Math.floor(this.props.slidesToShow),
                    slidesToScroll: Math.floor(this.props.slidesToShow),
                }}>
                    {
                        this.props.images.map((ob) =>
                            <SImgWithShadow key={ob.img} src={ob.img} onClick={()=>{
                                this.props.onImgClick(ob);
                            }} title={this.props.itemWidth}>
                            </SImgWithShadow>
                        )
                    }
                </Slider>
            </>
        )
    }

}
