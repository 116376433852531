import React from "react";

import LayoutService from "../Services/LayoutService"

export default class SLeftContent extends React.Component {

    render() {
        return (
            <div className="fullcon" style={{width: LayoutService.leftWidth}}>
                {this.props.children}
            </div>
        )
    }

}
