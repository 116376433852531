import React from "react";

export default class SShadowPanel extends React.Component {

    render() {
        return (
            <div className="bigshadow"
                 style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}>
                {this.props.children}
            </div>
        )
    }

}
