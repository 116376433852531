import './App.scss';
import React from "react";
import Main from "./Views/Main"
import TgbDataSource from "./DataSources/TgbDataSource";
import {HashRouter} from 'react-router-dom'
import AppSpecific from './AppSpecific';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VertretungsDataService from "./DataSources/VertretungsDataService";
import appSpecific from "./AppSpecificWebPreview";

document.addEventListener('contextmenu', (ev)=>{
    console.log("contextmenu prevented",ev);
    ev.preventDefault();
});

AppSpecific.initAppSecifics();

appSpecific.usePreviewData = window.location.href.indexOf("preview=true")!==-1;

VertretungsDataService.init();

window.isMobile = (/Mobi|Android/i.test(navigator.userAgent));

if(AppSpecific.isMobileDevice){
    window.isMobile = true;
}
String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};
console.log("isMobile", window.isMobile);

class App extends React.Component {
    constructor() {
        super();
        TgbDataSource.load();

    }

    render() {
        return (
            <HashRouter hashType="noslash">
                <Main className={AppSpecific.className}/>
            </HashRouter>
        );
    }
}

export default App;
