import React from "react";
import SportService from "../DataSources/SportService"
import StdContentDisplay from "./StdContentDisplay"


export default class SearchDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            sportcenters: [],
            categories: [],
            termine: [],
            angebote: []
        };

    }

    componentDidMount(){
       this.didMount=true;
        this.updateSearch(this.props.match.params.search);
    }
    updateSearch(search){
        console.log("updateSearch",search);
        if(this.didMount)
        if(this.currentSearch!==search){
            if(this.handler){
                SportService.removeHandler(this.handler);
            }
            this.handler = () => {

                this.state.loading=true;
                this.setState(this.state);
                setTimeout(()=>{
                    this.state.termine = SportService.findTerminByFullQueryAndSetCurrentFilter({
                        currentSearch: search
                    });


                    this.state.angebote = SportService.findSportAngeboteByTermine(this.state.termine);

                    this.state.sportcenters = SportService.findRelatedSportCentersForTermine(this.state.termine);

                    //this.state.categories = SportService.categorizeByAngebot(this.state.termine);

                    this.state.categories = SportService.categorizeByOneBigAngebot(this.state.termine,"Suchergebnisse für '"+search+"'",null);

                    this.state.description = "";

                    this.state.loading=false;
                    this.setState(this.state);
                },1);

            };
            if(this.handler) {
                this.currentSearch=search;
                SportService.onChange(this.handler);
            }
        }
    }
    componentWillReceiveProps(props){
        console.log("update props1",props,props.match.params.search);
        this.updateSearch(props.match.params.search);
    }
    componentWillUnmount(){

        if(this.handler){
            SportService.removeHandler(this.handler());
        }
    }

    render() {
        return (
            <StdContentDisplay description={this.state.description} name={"Suchergebnisse für "+this.props.match.params.search}
                               header={"Suchergebnisse für "+this.props.match.params.search}
                               image=""
                               loading={this.state.loading}
                               categories={this.state.categories}
                               lists={[{
                                   baseLink: "/angebot/",
                                   header: 'Angebote',
                                   list: this.state.angebote
                               },{
                                   baseLink: "/sportcenter/",
                                   header: 'Orte',
                                   list: this.state.sportcenters
                               }]}
            />
        )
    }
}