import CacheableDataSource from './CacheableDataSource';
import SportService from './SportService';
import PageDataService from './PageDataService'
import SettingsService from "./SettingsService"
import AppSpecific from '../AppSpecific';

class TgbDataSource {

    data = {};

    constructor() {
        this.changeListeners = [];
        this.errorListeners = [];
        this.lastLoad = new Date().getTime();
    }

    timeSinceLastLoadInMs() {
        return new Date().getTime() - this.lastLoad;
    }

    showError(text) {
        this.errorListeners.forEach((errorListener) => {
            errorListener(text);
        });
    }

    load() {
        console.log("HIEER");
        this.lastLoad = new Date().getTime();
        let that = this;
        let pageDataAndAppDataLoadedTest = function () {

            if (that.isLoaded()) {
                PageDataService.updateFromData(that.data.pageData);
                SportService.updateFromDataAfterPageDataLoaded(that.data.sportData);

                that.changeListeners.forEach((listener) => {
                    listener(that.data);
                });
            }
        };

        //TODO: change url
        CacheableDataSource.loadCachedJsonData(SettingsService.getMainPageUrl() + '?page=Data&type=appNews', (res, isCached, error) => {
            //try {
            if (error) {
                this.showError("error in loading part 1 " + error);
            }
            //this.showError("step0 " + isCached + " ");
            if (res) {
                //this.showError("step0.1");
                that.data.news = res.data;
                //this.showError("step1");
                res.appdata.forEach((entry) => {

                    //console.log("entry", entry.content);
                    try {
                        entry.content = entry.content.replaceAll(String.fromCharCode(13), ' ');
                        entry.content = entry.content.replaceAll(String.fromCharCode(32), ' ');
                        entry.content = entry.content.replaceAll(String.fromCharCode(9), ' ');
                        this.data[entry.id] = JSON.parse(entry.content);
                    } catch (e) {
                        this.showError("could not load content " + e);
                        console.error('could not load content ' + entry.id, e, entry.content.charCodeAt(10044), entry.content, entry);
                    }
                });

                if (AppSpecific.usePreviewData) {
                    that.data.pageData = this.data['cmsPreview'];
                    //this.showError("step4");
                } else {
                    //this.showError("step3");
                    that.data.pageData = this.data['cms'];
                }
                pageDataAndAppDataLoadedTest();
            }
            /*}catch(e){
                console.error('could not load content 2 ',e);
                alert("could not load content "+e);
            }*/
        });
        CacheableDataSource.loadCachedJsonData(SettingsService.getMainPageUrl() + '?page=Data&type=appSportData', (res, isCached, error) => {
            if (error) {
                this.showError("error in loading part 2 " + error);
            }
            if (res) {
                //this.showError("sportdata1");
                this.data.sportData = res;
                //this.showError("sportdata2");
                pageDataAndAppDataLoadedTest();
            }
        });
    };

    onError(func) {
        this.errorListeners.push(func);
    }

    onChange(func) {
        this.changeListeners.push(func);
        if (this.data.news) {
            func(this.data);
        }
    };

    isLoaded() {
        return this.data.news && this.data.pageData && this.data.sportData;
    }

    removeOnChange(func) {
        let idx = this.changeListeners.indexOf(func);
        if (idx >= 0) {
            this.changeListeners.splice(idx, 1);
        }
    }
}

export default new TgbDataSource();
