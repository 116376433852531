import React from "react";
import SportService from "../DataSources/SportService"
import StdContentDisplay from "./StdContentDisplay"


export default class SportCenterDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            sportCenter: {},
            sportcenters: [],
            categories: [],
            termine: [],
            angebote: []
        };

    }

    componentDidMount() {
        SportService.onChange(() => {


            this.state.sportCenter = SportService.sportCenterById[this.props.match.params.id];

            console.log("this.state.sportCenter ",this.state.sportCenter );
            this.state.description = "<b>" + this.state.sportCenter.name + "</b><br/><br/>" +
                this.state.sportCenter.description+"<br/><br/>"+
                "<b>Adresse:</b><br> " + this.state.sportCenter.address + "<br>"

            this.setState(this.state);

            setTimeout(()=>{
                this.state.termine = SportService.findTerminByFullQueryAndSetCurrentFilter({
                    sportCenterFilter: this.state.sportCenter.id
                });


                this.state.categories = SportService.categorizeByAngebot(this.state.termine);

                this.state.angebote = SportService.findSportAngeboteByTermine(this.state.termine);

                this.state.loading=false;
                this.setState(this.state);
            },1);




        });
    }

    render() {
        return (
            <StdContentDisplay description={this.state.description} name={this.state.sportCenter.name}
                               header={this.state.sportCenter.name}
                               image={this.state.sportCenter.img}
                               loading={this.state.loading}
                               categories={this.state.categories}
                               lists={[{
                                   baseLink: "/angebot/",
                                   header: 'Angebote',
                                   list: this.state.angebote
                               }]}
            />
        )
    }
}
