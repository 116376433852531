class UtilService {

    constructor() {
    }

    convertToSlugString(str){
        return str.toLowerCase().replace(/[^0-9a-z]/gi, '');
    }
}

export default new UtilService();