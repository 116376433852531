import React from "react";
import SportService from "../DataSources/SportService"
import StdContentDisplay from "./StdContentDisplay"


export default class Group extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            loading:true,
            group: {},
            sportcenters: [],
            categories: [],
            termine: [],
            angebote: []
        };
    }

    componentDidMount() {
        SportService.onChange(() => {
            this.state.group = SportService.sportCenterGroupHashMap[this.props.match.params.groupId];

            this.setState(this.state);

            setTimeout(()=>{
                this.state.sportcenters = SportService.findRelatedSportCentersForGroup(this.state.group);

                this.state.termine = SportService.findTerminByFullQueryAndSetCurrentFilter({
                    sportCenterGroupFilter: this.state.group.id
                });
                this.state.categories = SportService.categorizeByAngebot(this.state.termine);

                this.state.angebote = SportService.findSportAngeboteByTermine(this.state.termine);
                this.state.loading=false;
                this.setState(this.state);
            },1);

        });
    }

    render() {
        return (
            <StdContentDisplay description={this.state.group.description} name={this.state.group.fullText}
                               header={this.state.group.fullText}
                               image={this.state.group.fullImage}
                               imageLink ={this.state.group.fullImageLink}
                               loading={this.state.loading}
                               categories={this.state.categories}
                               lists={[ {
                                   baseLink: "/angebot/",
                                   header: 'Angebote',
                                   list: this.state.angebote
                               },{
                                   baseLink: "/sportcenter/",
                                   header: 'Orte',
                                   list: this.state.sportcenters
                               }]}
            />
        )
    }
}