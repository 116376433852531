import React from "react";
import LoginDisplay from "./LoginDisplay";
import PageDataService from "../../DataSources/PageDataService";
import LayoutService from "../../Services/LayoutService";
import SMainPageLabel from "../../StyleComponents/SMainPageLabel";

export default class Abteilung extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {

        PageDataService.onChange(() => {
            this.didReceiveData = true;
            this.updateView(this.props);
        });

        console.log('componentDidMount');
    }

    componentWillReceiveProps(nextProps) {

        //console.log('nextProps',nextProps);
        this.updateView(nextProps);
    }

    updateView(props) {
        if (this.didReceiveData) {
            if (props.match.params.abteilungsId) {
                this.state.description = PageDataService.getVGymSeite(props.match.params.abteilungsId, props.match.params.subPage);
                this.state.loading = false;
                this.state.noLoginRequired = props?.match.params.abteilungsId === 'podcast';
                this.setState(this.state);

                this.state.menu = PageDataService.getAbteilungsMenu(props.match.params.abteilungsId);
                console.log("this.state.hierarchy", this.state.menu);
            } else {
                this.state.loading = false;
                this.state.description = null;
                this.setState(this.state);
            }
        }
    }

    render() {
        return (
            this.state.description &&
            <>
                {
                    this.state.noLoginRequired &&
                    <div style={{
                        width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        display: 'flex', flexDirection: 'column'
                    }}>
                        <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                            <SMainPageLabel text={this.state.title}/>
                            <div className="bigshadow description"
                                 style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}
                            >

                                <div dangerouslySetInnerHTML={{__html: this.state.description}}></div>
                                <div style={{clear: "both"}}></div>


                            </div>

                        </div>

                    </div>

                }
                {
                    !this.state.noLoginRequired &&
                    <LoginDisplay displayName="Virtuelles Gym">
                        <div style={{
                            width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            display: 'flex', flexDirection: 'column'
                        }}>
                            <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                                <SMainPageLabel text={this.state.title}/>
                                <div className="bigshadow description"
                                     style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}
                                >

                                    <div dangerouslySetInnerHTML={{__html: this.state.description}}></div>
                                    <div style={{clear: "both"}}></div>


                                </div>

                            </div>

                        </div>
                    </LoginDisplay>
                }
            </>
        )
    }
}


