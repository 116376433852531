import Slider from "react-slick/lib";
import {Link} from "react-router-dom";
import React from "react";
import SImageWithText from "../StyleComponents/SImageWithText"


export default class ImageListWithText extends React.Component {

    render() {
        // baselink
        // sliderWidth

        var height = this.props.itemHeight;

        var width = this.props.itemCalcWidth;
        var angebotCount = Math.max(1, this.props.sliderWidth / width);
        var angebotPerClickCount = angebotCount;

        if(window.isMobile){
            return (

                <div className="mobile-slider">{
                    this.props.list.map((ob) =>
                        <SImageWithText key={ob.id} link={this.props.baselink + ob.id} img={ob.img} height={height}
                                        width={this.props.itemWidth} text={ob.name}/>
                    )
                }
                </div>
            );
        }
        return (

            <Slider style={{marginLeft: 10, marginRight: 10}}
                    {...{
                        arrows: true,
                        variableWidth: false,
                        speed: 500,
                        infinite: false,
                        dots: false,
                        slidesToShow: angebotCount,
                        slidesToScroll: angebotPerClickCount
                    }}>
                {
                    this.props.list.map((ob) =>
                        <SImageWithText key={ob.id} link={this.props.baselink + ob.id} img={ob.img} height={height} width={this.props.itemWidth} text={ob.name}/>
                    )
                }
            </Slider>
        );
    }
}

