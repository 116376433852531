import React from "react";
import SDescriptionContainer from "../../StyleComponents/SDescriptionContainer";
import SFullContentPage from "../../StyleComponents/SFullContentPage";
import SMainPageLabel from "../../StyleComponents/SMainPageLabel";
import SShadowPanel from "../../StyleComponents/SShadowPanel";
import SpecialPageDisplay from "../SpecialPageDisplay";


export default class ImpressumDisplay extends React.Component {

    render() {
        return (
            <SFullContentPage>
                <SpecialPageDisplay title="Impressum" slug="impressum"/>
            </SFullContentPage>

        )
    }
}