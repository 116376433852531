import React from "react";
import SportService from "../DataSources/SportService"
import StdContentDisplay from "./StdContentDisplay"


export default class SportAngebotDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            sportAngebot: {},
            sportcenters: [],
            categories: [],
            termine: [],
            angebote: []
        };

    }
    componentDidMount() {

        SportService.onChange(() => {

            this.state.sportAngebot = SportService.angebotById[this.props.match.params.id];


            this.state.description=this.state.sportAngebot.description;// "<b>"+this.state.sportAngebot.name+"</b><br/><br/>Beschreibungen kommen bald...";
            this.setState(this.state);

            setTimeout(()=>{

                this.state.termine = SportService.findTerminByFullQueryAndSetCurrentFilter({
                    sportArtGroupFilter: this.state.sportAngebot.id
                });

                this.state.sportcenters = SportService.findRelatedSportCentersForTermine(this.state.termine);

                this.state.categories = SportService.categorizeByAngebot(this.state.termine);

                //this.state.angebote = SportService.findSportAngeboteByTermine(this.state.termine);
                console.log("termine", this.state.termine, this.state.categories);
                this.state.loading = false;

                this.setState(this.state);
            },1);

        });
    }

    render() {
        return (
            <StdContentDisplay description={this.state.description} name={this.state.sportAngebot.name}
                               header={this.state.sportAngebot.name}
                               image={this.state.sportAngebot.img}
                               loading={this.state.loading}
                               categories={this.state.categories}
                               lists={[{
                                   baseLink: "/sportcenter/",
                                   header: 'Orte',
                                   list: this.state.sportcenters
                               }]}
            />
        )
    }
}