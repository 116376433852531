import React from "react";
import LayoutService from "../Services/LayoutService"
import SMainPageLabel from "../StyleComponents/SMainPageLabel"

import PageDataService from "../DataSources/PageDataService"
import BaseAfterLoadComponent from "./BaseAfterLoadComponent";

export default class PageDisplayById extends BaseAfterLoadComponent {

    constructor() {
        super();
        this.state = {
            title: '',
            description: ''
        }
    }

    allDataReceived() {
        this.setState({
            title: this.props.title,
            description: PageDataService.getPageById(this.props.id).content.rendered
        });
    }

    render() {
        return (
            <div style={{
                width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                alignItems: 'flex-start',
                display: 'flex', flexDirection: 'column'
            }}>
                <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                    <SMainPageLabel text={this.state.title}/>
                    <div className="bigshadow description"
                         style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}
                         dangerouslySetInnerHTML={{__html: this.state.description}}>

                    </div>

                </div>

            </div>
        )
    }
}
