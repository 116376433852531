import React from "react";
import ReactModal from "../Views/HomeDisplay";
import LayoutService from "../Services/LayoutService";

export default class SImgWithShadow extends React.Component {

    render() {
        return (
            <div className="smallshadow" style={{
                margin: 10,
                marginLeft: 0,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                width:this.props.width
            }}

            >
                <img height="200" src={this.props.src}

                     onClick={this.props.onClick}
                     style={{
                         borderTopLeftRadius: 10,
                         borderTopRightRadius: 10,
                         borderBottomRightRadius: 10,
                         borderBottomLeftRadius: 10
                     }}/>
            </div>
        )
    }

}
