import {Link} from "react-router-dom";
import React from "react";
import TimeHelper from "../Services/TimeHelper";
import TimeBoxSingleElementDisplay from "./TimeBoxSingleElementDisplay";

export default class TimeBoxDisplay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nowTimeFrame: TimeHelper.getNowTimeFrame(),
            currentFilteredList: []
        };

        this.componentWillReceiveProps(props);
    }

    onDayClick(day) {
        this.state.nowTimeFrame.day = day;
        this.onSetTime(this.state.nowTimeFrame.hour);
    }

    onSetTime(time) {
//        console.log("nowTimeFrame", this.state.nowTimeFrame);
        let nowTimeFrame = this.state.nowTimeFrame;
        nowTimeFrame.hour = time;
        nowTimeFrame.start = nowTimeFrame.hour * 60;
        nowTimeFrame.ende = nowTimeFrame.start + 60;
        this.updatePreviewTime(this.state.fullData);
    }

    onTimeClick(timeAdd) {
        let nowTimeFrame = this.state.nowTimeFrame;

        var result = this.state.fullData;
        var firstHour = 24 * 60;
        var lastHour = 0;
        var hour = nowTimeFrame.hour + timeAdd;
        let timeStart = hour * 60;
        let timeEnde = hour * 60 + 60;
        if (result)
            result.forEach((termin) => {

                if (termin.day === nowTimeFrame.day) {
                    if (termin.start < firstHour) {
                        firstHour = termin.start;
                    }
                    if (termin.ende > lastHour) {
                        lastHour = termin.ende;
                    }
                }

            });
        if (nowTimeFrame.hour + timeAdd < 7) {
            //console.log("left "+timeStart+ " "+firstHour);
            return;
        }
        if (nowTimeFrame.hour + timeAdd > 23) {
            //console.log("left2 "+timeEnde+ " "+lastHour);
            return;
        }

//        console.log("settime", this);
        this.onSetTime(nowTimeFrame.hour + timeAdd);
    }

    componentWillReceiveProps(props) {

        //console.log("componentwillreceiveprops", props);
        if (props.fullData) {
            this.updatePreviewTime(props.fullData);
        } else if (props.categoryData && props.categoryData.length) {
            this.updatePreviewTime(props.categoryData[0].originalTermine);
        }

    }

    updatePreviewTime(fullData) {


        let nowTimeFrame = this.state.nowTimeFrame;
        //console.log("updatePreviewTime", fullData);
        if (this.state.fullData === fullData && this.lastDay === this.state.nowTimeFrame.day && this.lastHour === this.state.nowTimeFrame.hour && !this.noSkip) {
            //console.log("skipping rerender");
            return;
        }
        this.noSkip = false;
        var result = fullData;
        let timeStart = nowTimeFrame.start;
        let timeEnde = nowTimeFrame.ende;
        this.lastDay = this.state.nowTimeFrame.day;
        this.lastHour = this.state.nowTimeFrame.hour;
        // console.log("setting fulldata ", fullData);
        this.state.fullData = fullData;


        var filtered = [];

        let maxResults = 1000;

        if (this.props.limitLines && !this.ignoreLimits) {
            if (window.isMobile) {
                maxResults = 4;
            } else {
                maxResults = 8;
            }
        }
        this.state.limited = false;
        let that = this;
        if (result)

            result.forEach((termin) => {

                if (termin.day === nowTimeFrame.day) {

                    var letIn = false;
                    if (timeStart >= termin.start && timeStart < termin.ende) {
                        letIn = true;
                    } else if (termin.start >= timeStart && termin.start < timeEnde) {
                        letIn = true;
                    } else if (termin.ende < timeEnde && termin.ende > timeStart) {
                        letIn = true;
                    }
                    if (letIn) {
                        if (filtered.length < maxResults) {
                            filtered.push(termin);
                        } else {
                            that.state.limited = true;
                        }
                    }
                }

            });


        console.log("filtered", filtered);
        this.state.nowTimeFrame = nowTimeFrame;
        this.state.currentFilteredList = filtered;
        this.setState(this.state);
    }

    render() {
        // baselink
        // sliderWidth

        return (
            <div className="smallshadow"
                 style={{
                     width: this.props.width,
                     maxWidth: this.props.width,
                     backgroundColor: "#ffffff",
                     padding: 14,
                     borderRadius: 10
                 }}>
                <div style={{
                    display: "flex", flexShrink: 0, flexWrap: "wrap", marginBottom: "15px",
                    justifyContent: "center"
                }}>

                    <div className="timebox-week-container">
                        <div onClick={() => {
                            this.onDayClick(0)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 0 ? " active" : "")}>Mo
                        </div>
                        <div onClick={() => {
                            this.onDayClick(1)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 1 ? " active" : "")}>Di
                        </div>
                        <div onClick={() => {
                            this.onDayClick(2)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 2 ? " active" : "")}>Mi
                        </div>
                        <div onClick={() => {
                            this.onDayClick(3)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 3 ? " active" : "")}>Do
                        </div>
                        <div onClick={() => {
                            this.onDayClick(4)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 4 ? " active" : "")}>Fr
                        </div>
                        <div onClick={() => {
                            this.onDayClick(5)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 5 ? " active" : "")}>Sa
                        </div>
                        <div onClick={() => {
                            this.onDayClick(6)
                        }}
                             className={"timeOverviewSelectDay" + (this.state.nowTimeFrame.day === 6 ? " active" : "")}>So
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: "20px",
                        width: "200px"
                    }}>

                        <div className="timebox-arrow"
                             onClick={() => {
                                 this.onTimeClick(-1)
                             }}>
                            <i className="fas fa-caret-left"/>
                        </div>
                        <div>{TimeHelper.getTimeStr(this.state.nowTimeFrame.start) + " - " + TimeHelper.getTimeStr(this.state.nowTimeFrame.ende)}</div>
                        <div className="timebox-arrow" onClick={() => {
                            this.onTimeClick(1)
                        }}><i className="fas fa-caret-right"/>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexShrink: 0, flexWrap: "wrap"}}>
                    {
                        this.state.currentFilteredList.length > 0 &&
                        this.state.currentFilteredList.map((entry) =>
                            <TimeBoxSingleElementDisplay termin={entry}/>

                        )
                    }
                    {
                        this.state.currentFilteredList.length === 0 &&
                        <div>
                            zur ausgewählten Zeit keine Sportstunden verfügbar
                        </div>
                    }
                </div>
                {
                    this.state.limited &&
                    <div className="timebox-limited" onClick={() => {
                        this.ignoreLimits = true;
                        this.noSkip = true;
                        this.onSetTime(this.state.nowTimeFrame.hour);
                    }}>
                        <div className="text">Alle Ergebnisse anzeigen</div>
                    </div>
                }
            </div>);
    }
}

