import ErrorContainerComp from "./ErrorContainerComp";
import React, {useState} from "react";

export default (props) => {

    const [reRenderId, setReRenderId] = useState(1);
    const triggerRender = () => {
        setReRenderId((v) => {
            return v + 1;
        })
    };
    let type = props.type||"text";
    let fieldErrors = props.state.fieldErrors;
    if(props.targetOb){
        if(!props.targetOb.fieldErrors){
            props.targetOb.fieldErrors={};
        }
        fieldErrors = props.targetOb.fieldErrors;
    }
    return <>
        {
            (!!reRenderId)&&<ErrorContainerComp error={fieldErrors[props.name]}>
                <input
                    type={type}
                    name={props.name}
                    autoComplete={props.autoComplete}
                    value={props.state.getValue(props.name,props.targetOb)}
                    onChange={(e) => {
                        fieldErrors[props.name] = "";
                        props.state.onChange(props.name, e.target.value,props.targetOb);
                        triggerRender();
                    }}
                />
            </ErrorContainerComp>
        }
    </>

}