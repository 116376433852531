import React from "react";

export default class SMainPageLabelWithBottomMargin extends React.Component {

    render() {
        return (
            <div className="mainPageLabel" style={{marginTop: 20, marginBottom: 6}}>
                {this.props.text}
            </div>
        )
    }

}
