import React from "react";
import SpecialPageDisplay from "../SpecialPageDisplay"

export default class MitgliedWerdenDisplay extends React.Component {

    constructor() {
        super();

    }

    render() {
        return (
            <SpecialPageDisplay title="Mitglied werden!" slug="mitglied-werden"/>
        )
    }
}