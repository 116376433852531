import {Link} from "react-router-dom";
import VertretungsDataService from "../DataSources/VertretungsDataService";
import vertretungsDataService from "../DataSources/VertretungsDataService";
import {EnterNumberDisplayAccess} from "./EnterNumberDisplay";

export default (props) => {

    const entry = props.termin;
    let permanentId = entry.permanentId;

    let timeChanged = false;

    let ortChanged = false;

    let trainerChanged = false;
    let vertretungsDaten = VertretungsDataService.getVertretungsDatenByPermaId(permanentId);

    let canceled = false;
    let vertretungChanged = false;
    let newTime = "";
    let freitext = undefined;
    let freitextActive = false;
    let ort = undefined;
    let trainer = undefined;
    if (vertretungsDaten && vertretungsDaten.length > 0) {

        let vertretung = vertretungsDaten[0];
        vertretungChanged = true;
        if (vertretung.freitext) {
            freitextActive = true;
            freitext = vertretung.freitext;
        }
        if (vertretung.canceled) {
            canceled = true;
        }
        if (vertretung.uhrzeit) {
            timeChanged = true;
            vertretungChanged = true;
            newTime = "ab " + vertretung.uhrzeit;
        }
        if (vertretung.ort) {
            ort = vertretung.ort;
            vertretungChanged = true;
            ortChanged = true;
        }
        if (vertretung.trainer) {
            trainer = vertretung.trainer;
            vertretungChanged = true;
            trainerChanged = true;
        }
    }

    return <Link className="timeBoxElement" to={"/sportart/" + entry.sportArt.id} key={entry.id}
                 onTouchEnd={(ev)=>{
                     EnterNumberDisplayAccess.handleTouchEnd(ev,props.termin);
                 }}
                 onTouchStart={(ev)=>{
                     EnterNumberDisplayAccess.handleTouchStart(ev,props.termin);
                 }}
                 onMouseDown={(ev) => {
                     console.log("mousestart",ev);
                     if (ev.button === 2) {
                         if (vertretungsDataService.baseAdminAccess)
                         {

                             ev.stopPropagation();
                             ev.preventDefault();
                             EnterNumberDisplayAccess.ActivatePopup(props.termin);
                         }
                     }
                 }}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div className={"timeOverviewName" + (canceled ? " canceledText" : "")}>
                {entry.sportArtName}
            </div>
            {
                canceled &&
                <div className={"timeOverviewVonBis canceledText"}>
                    (Fällt aus)
                </div>
            }
            {
                freitextActive &&
                <div className={"timeOverviewVonBis vertretungChanged" + (canceled ? " canceledText" : "")}>
                    {freitext}
                </div>
            }
            <div className="timeOverviewVonBis">
                {
                    timeChanged && <>
                        <span className={" vertretungChanged"}>{newTime}</span>
                        <span className={" crossedOut"}>{entry.vonBis}</span>
                    </>
                }
                {
                    !timeChanged &&
                    <span className={""}>{entry.vonBis}</span>
                }
            </div>
            <div className="timeOverviewVonBis">
                {
                    ortChanged &&
                    <span className={" vertretungChanged"}>{ort}</span>
                }
                {
                    !ortChanged &&
                    <span>{entry.sportcenter.name}</span>
                }

            </div>
            <div className={"timeOverviewTrainer" + (trainerChanged ? " vertretungChanged" : "")}>
                {
                    trainerChanged && <span>
                    {trainer}
                    </span>
                }
                {
                    !trainerChanged && <span>
                    {entry.trainerName}
                    </span>
                }
            </div>
        </div>
    </Link>
}