class StyleService {


    createStyleSheet(obj) {
        return obj;
    }


}

export default new StyleService();