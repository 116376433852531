import React from "react";
import LayoutService from "../Services/LayoutService"
import ImageListWithText from "../Views/ImageListWithText"
import {Link} from "react-router-dom";
import TimeBoxDisplay from "./TimeBoxDisplay";
import SMainPageLabel from "../StyleComponents/SMainPageLabel"
import TableSingleElementDisplay from "./TableSingleElementDisplay";


export default class StdContentDisplay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {days: []};


        this.state.leftWidth = 130;

        let width = LayoutService.fullContentWidth - 60;
        //width -= this.state.leftWidth;
        //width-=50;
        this.state.dayWidth = (width - 20) / 8;
        this.state.leftWidth = this.state.dayWidth + 20;
        for (let i = 0; i < 7; i++) {
            this.state.days.push(i);
        }
    }

    render() {

        return (
            <div style={{
                width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                alignItems: 'flex-start',
                display: 'flex', flexDirection: 'row'
            }}>
                <div style={{
                    width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    display: 'flex', flexDirection: 'row'
                }}>
                    <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                        <SMainPageLabel text={this.props.header}/>
                        {
                            this.props.description && this.props.description.length > 10 &&
                            <div className="bigshadow"
                                 style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}>

                                {
                                    LayoutService.rightWidth > 10 &&
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }}>
                                        <div>
                                            <div className="description"
                                                 dangerouslySetInnerHTML={{__html: this.props.description}}>
                                            </div>
                                        </div>
                                        {
                                            this.props.image &&
                                            <div className="heightAlignImage">
                                                <img src={this.props.image}
                                                     height="160"
                                                     onClick={() => {
                                                         if (this.props.imageLink) {
                                                             window.showIframe(this.props.imageLink);
                                                         }
                                                     }}
                                                     className="bigshadow"/>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    LayoutService.rightWidth <= 10 &&
                                    <div>
                                        {
                                            this.props.image &&
                                            <div>
                                                <img src={this.props.image} width="100%" className="bigshadow"
                                                     onClick={() => {
                                                         if (this.props.imageLink) {
                                                             window.showIframe(this.props.imageLink);
                                                         }
                                                     }}/>
                                            </div>
                                        }
                                        <div className="description"
                                             dangerouslySetInnerHTML={{__html: this.props.description}}>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {
                            this.props.loading &&
                            <div className="loadingText">Daten werden verarbeitet...</div>
                        }

                        {
                            !this.props.loading &&
                            <div style={{marginTop: "10px"}}>
                                <TimeBoxDisplay horizontal={true} categoryData={this.props.categories}
                                                width={LayoutService.fullContentWidth}/>
                            </div>
                        }
                        {
                            !this.props.loading &&
                            <SMainPageLabel>
                                Termine
                            </SMainPageLabel>
                        }
                        {
                            !this.props.loading &&
                            this.props.categories.map((category) =>
                                <div className="bigshadow" key={category.img}
                                     style={{
                                         marginTop: 10,
                                         marginBottom: 80,
                                         backgroundColor: '#ffffff',
                                         padding: 20,
                                         minHeight: 180
                                     }}>

                                    <div className="topSportListLine" style={{
                                        display: 'flex', justifyContent: 'space-between',
                                        position: 'relative'
                                    }}>
                                        <div style={{
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                            marginBottom: 10
                                        }}>
                                            {category.name}
                                        </div>
                                    </div>
                                    <div>

                                        {
                                            LayoutService.fullContentWidth >= 610 &&
                                            <table className="belegplanTable">
                                                <thead>
                                                <tr>
                                                    <td className="firstCol"
                                                        style={{width: this.state.leftWidth}}>Sportart
                                                    </td>
                                                    <td style={{width: this.state.dayWidth}}>Montag</td>
                                                    <td style={{width: this.state.dayWidth}}>Dienstag</td>
                                                    <td style={{width: this.state.dayWidth}}>Mittwoch</td>
                                                    <td style={{width: this.state.dayWidth}}>Donnerstag</td>
                                                    <td style={{width: this.state.dayWidth}}>Freitag</td>
                                                    <td style={{width: this.state.dayWidth}}>Samstag</td>
                                                    <td style={{width: this.state.dayWidth}}>Sonntag</td>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    category.table.map((row) =>
                                                        <tr key={row.id}>
                                                            <td className="firstCol" style={{
                                                                width: this.state.leftWidth,
                                                                maxWidth: this.state.leftWidth
                                                            }}>
                                                                <Link to={"/sportart/" + row.termin.sportArt.id}>
                                                                    {row.termin.sportArtName}
                                                                </Link>
                                                            </td>
                                                            {
                                                                this.state.days.map((day) =>
                                                                    <td key={day} style={{
                                                                        width: this.state.dayWidth,
                                                                        maxWidth: this.state.dayWidth
                                                                    }}>
                                                                        {
                                                                            row[day]&&
                                                                            <TableSingleElementDisplay
                                                                                termin={row[day]}
                                                                                dayWidth={this.state.dayWidth}
                                                                            />
                                                                        }
                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        }
                                        {
                                            LayoutService.fullContentWidth < 610 &&
                                            <div className="belegplanDisplay">
                                                {
                                                    category.tree.map((row) =>
                                                        <Link to={"/sportart/" + row.termin.sportArt.id} key={row.id}
                                                              className={"belegplanDisplayRow"}>
                                                            <div className="headerName">
                                                                {row.termin.sportArtName}
                                                            </div>
                                                            {
                                                                row.termine.map((termin) =>
                                                                    <div className="terminEintrag">
                                                                        {termin.dayText} {termin.vonBis}
                                                                    </div>
                                                                )
                                                            }
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                            )
                        }

                        {
                            !this.props.loading &&
                            this.props.lists.map((list) =>
                                <div key={list.header}>
                                    <SMainPageLabel>
                                        {list.header}
                                    </SMainPageLabel>
                                    <ImageListWithText sliderWidth={LayoutService.fullContentWidth}
                                                       baselink={list.baseLink}
                                                       itemHeight={120} itemCalcWidth={195} itemWidth={180}
                                                       list={list.list}/>
                                </div>
                            )
                        }


                    </div>

                </div>
            </div>
        )
    }
}