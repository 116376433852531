import React from "react";
import TgbDataSource from "../DataSources/TgbDataSource";
import SportService from "../DataSources/SportService";
import LayoutService from "../Services/LayoutService";


export default class BaseAfterLoadComponent extends React.Component {

    constructor() {
        super();
    }

    componentWillMount() {
        let that = this;

        this._onDataChanced = function () {
            that.allDataReceived();
        };
        TgbDataSource.onChange(this._onDataChanced);
    }

    allDataReceived() {
        console.log("allDataReceived");
    }

    componentWillUnmount() {
        TgbDataSource.removeOnChange(this._onDataChanced);
    }

    render() {
        return (
            <div>{this.props.children}</div>
        )
    }
}