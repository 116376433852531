class MainFlowManager {

    constructor() {
        this.changeListeners = [];
    }
    showModalImage(image) {

        let evt={
            image:image
        };
        this.changeListeners.forEach(function(handler){
            handler(evt);
        });
    }

    showModalImageList(images) {

        let evt={
            images:images
        };
        this.changeListeners.forEach(function(handler){
            handler(evt);
        });
    }

    onChange(func) {
        this.changeListeners.push(func);
    };

    removeOnChange(func) {
        let idx = this.changeListeners.indexOf(func);
        if (idx >= 0) {
            this.changeListeners.splice(idx, 1);
        }
    }
}

export default new MainFlowManager();