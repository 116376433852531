import React from "react";
import {Link} from "react-router-dom";

export default class SImageWithText extends React.Component {

    render() {
        return (
            <Link to={this.props.link} className="smallshadow" style={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10,
                    display: 'flex',

                    alignItems: 'stretch',
                    justifyContent: 'center',
                    flexDirection: 'column'

                }}>
                    <img height={this.props.height} width={this.props.width} src={this.props.img} style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                    }}/>
                    <div style={{
                        textAlign: 'center',
                        padding: 4,
                        fontSize: 12,
                        color: '#888888',
                        backgroundColor: '#ffffff'
                    }}>
                        {this.props.text}
                    </div>
            </Link>
        )
    }

}
