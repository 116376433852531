import React from "react";
import LayoutService from "../../Services/LayoutService";
import SettingsService from "../../DataSources/SettingsService";
import {view, store} from '@risingstack/react-easy-state';

import moment from "moment";

import Globals from "./Globals";

// https://github.com/RisingStack/react-easy-state

export default view(class LoginDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            msg: "",
            loggedIn: false,
        };

        let date = new Date();

        if (date.getDate() === 10 && (1 + date.getMonth()) === 9 && (date.getFullYear() === 2022)) {
            console.log("force logged in true");
            this.state.loggedIn = true;
        } else {
            console.log("not force logged in ");
            try {
                if (localStorage) {
                    let storedBarcode = localStorage.getItem('virtualGymLoginBarcode');
                    let storedGeburtsDatum = localStorage.getItem('virtualGymLoginGebDatum');
                    let storedLoginTime = localStorage.getItem('virtualGymLogin');
                    if (storedLoginTime && storedGeburtsDatum) {
                        if (moment(storedLoginTime).add(30, 'days').isAfter(moment())) {
                            console.log("logged in because of recent login ");
                            this.state.loggedIn = true;
                            Globals.loggedIn = true;
                            Globals.geburtsDatum = storedGeburtsDatum;
                            Globals.barcode = storedBarcode;
                        }
                    }
                }
            } catch (e) {
                console.error('error in login storing ', e);
            }
        }


    }

    setLoggedIn() {
        let barcode = document.getElementById("barcode").value;
        let geburtsdatum = document.getElementById("geburtsdatum").value;

        this.state.msg = "...";
        this.setState(this.state);
        fetch(SettingsService.getMainPageUrl() + "?page=Data&module=checkMember&geburtstag=" + geburtsdatum + "&barcode=" + barcode + "&time=" + (new Date().getTime()))
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                if (responseJson["accepted"]) {
                    this.state.loggedIn = true;
                    Globals.loggedIn = true;
                    Globals.barcode = barcode;
                    Globals.geburtsDatum = geburtsdatum;
                    try {
                        if (localStorage) {
                            localStorage.setItem("virtualGymLogin", moment().format());
                            localStorage.setItem("virtualGymLoginBarcode", barcode);
                            localStorage.setItem("virtualGymLoginGebDatum", geburtsdatum);
                        }
                    } catch (e) {
                        console.error('could not store login in local storage', e);
                    }
                    this.setState(this.state);
                } else {
                    this.state.msg = "Daten '" + barcode + "': '" + geburtsdatum + "' nicht gültig!";
                    this.setState(this.state);
                }
            })
            .catch((error) => {
                this.state.msg = "Es ist ein fehler passiert";
                Globals.loggedIn = false;
                Globals.barcode = null;
                Globals.geburtsDatum = null;
                this.setState(this.state);
                console.error(error);
            });

    }

    componentDidMount() {


    }

    render() {
        if (this.state.loggedIn) {
            return (<div>{this.props.children}</div>);
        } else {

            return (
                <div style={{
                    width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    display: 'flex', flexDirection: 'column'
                }}>
                    <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                        <div className="login-form">
                            <div className="container">
                                <form onSubmit={(e) => {
                                    this.setLoggedIn();
                                    e.preventDefault();
                                }}>
                                    <h1>
                                        {this.props.displayName} - Nur für Mitglieder
                                    </h1>
                                    <div className="form-content">
                                        <input id="barcode" name="barcode" placeholder="Barcode" type="text"/><input
                                        id="geburtsdatum" name="geburtsdatum" placeholder="Geburtsdatum (z.B. 1.2.1990)"
                                        type="text"/><br/>
                                        <div className="button" onClick={() => {

                                            this.setLoggedIn();
                                        }}>
                                            Log in
                                        </div>
                                        <br/>
                                        <div className="signup-message" style={{color: "red"}}>
                                            {this.state.msg}
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className="signup-message">
                                            <a href="#mitgliedwerden">Noch kein Mitglied?</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            )
        }
    }
});
