import React from "react";
import LayoutService from "../../Services/LayoutService"
import SShadowPanel from "../../StyleComponents/SShadowPanel"
import SMainPageLabel from "../../StyleComponents/SMainPageLabel"
import SRow from "../../StyleComponents/SRow"
import SDescriptionContainer from "../../StyleComponents/SDescriptionContainer"
import SImageShowSlider from "../../StyleComponents/SImageShowSlider";
import SFullContentPage from "../../StyleComponents/SFullContentPage";
import MainFlowManager from "../../Services/MainFlowManager";
import BaseAfterLoadComponent from "../BaseAfterLoadComponent";
import PageDataService from "../../DataSources/PageDataService";
import SettingsService from "../../DataSources/SettingsService";

export default class StudioDisplay extends BaseAfterLoadComponent {

    constructor() {
        super();


        var images = [];

        for (let i = 1; i <= 8; i++) {
            images.push({img: SettingsService.getMainPageUrl()+"img/fitness/" + i + "_800.jpg"});
            // (())            img.setValue("srcBig", "http://www.tgbornheim.de/img/fitness/" + i + "_800.jpg");
            // images.pushChild(img);
        }
        this.state = {
            images: images,
            description:'',
            shortDescription:''
        };

    }
    allDataReceived() {

        this.state.description = PageDataService.getSportStaettenDescription('fitnessstudiodetails');
        this.state.shortDescription = PageDataService.getSportStaettenDescription('fitnessstudiokurzbeschreibung');
        this.setState(this.state);
    }

    render() {
        return (
            <SFullContentPage>
                <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                    <SMainPageLabel>
                        Fitness Studios der Turngemeinde Bornheim
                    </SMainPageLabel>
                    <SShadowPanel>
                        <div className="description" dangerouslySetInnerHTML={{__html: this.state.shortDescription}}>
                        </div>
                    </SShadowPanel>


                </div>

                <SMainPageLabel text="Bilder"/>

                <SImageShowSlider images={this.state.images} slidesToShow={2}
                                  onImgClick={(ob) => {
                                      MainFlowManager.showModalImage(ob.img);
                                  }}/>

                <div style={{width: "100%"}}>
                    <SMainPageLabel text="Beschreibung"/>
                    <SShadowPanel>
                        <div className="description" dangerouslySetInnerHTML={{__html: this.state.description}}>
                        </div>
                    </SShadowPanel>
                </div>
            </SFullContentPage>
        )
    }
}
