import React from "react";
import LayoutService from "../Services/LayoutService";
import SRow from "./SRow"

export default class SRow100Percent extends React.Component {

    render() {
        return (
            <div style={{
                width: "100%",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                {this.props.children}
            </div>
        )
    }

}
