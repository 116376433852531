import React from "react";

export default class SDescriptionContainer extends React.Component {

    render() {
        return (
            <div className="description descriptionGrow">
                {this.props.children}
            </div>
        )
    }

}
