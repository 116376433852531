import moment from "moment"

export default class TimeHelper {

    static twoDigits(hour) {
        if (hour >= 10) {
            return hour + "";
        }
        return "0" + hour;
    }

    static getTimeStr(start) {
        let hour = Math.trunc(start / 60);
        let minutes = start - hour * 60;

        return TimeHelper.twoDigits(hour) + ":" + TimeHelper.twoDigits(minutes);
    }


    static getNowTimeFrame() {

        var now = moment();

        var hour = now.hours();

        var currentDay = (now.day() + 6) % 7;
        var isNextDay = false;
        if (hour < 8) {
            hour = 8;
        }
        if (hour > 23) {
            isNextDay = true;
            hour = 10;
            currentDay = (currentDay + 1) % 7;
        }


        return {
            day: currentDay,
            isNextDay: isNextDay,
            hour: hour,
            nowtime: hour * 60 + now.minutes(),
            start: hour * 60,
            ende: (hour + 1) * 60
        }

    }

    static getDayText(day) {
        if (day === 0) {
            return "Montag";
        }
        if (day === 1) {
            return "Dienstag";
        }
        if (day === 2) {
            return "Mittwoch";
        }
        if (day === 3) {
            return "Donnerstag";
        }
        if (day === 4) {
            return "Freitag";
        }
        if (day === 5) {
            return "Samstag";
        }
        if (day === 6) {
            return "Sonntag";
        }
        return "";
    }
}
