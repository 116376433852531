class LayoutService {

    constructor() {
        this.listeners = [];
    }


    triggerLayoutChange() {
        this.listeners.forEach(function(listener){
            listener();
        });
    }

    onDimensionChange(func) {
        this.listeners.push(func);
    }

    removeOnChange(func) {
        let idx =this.listeners.indexOf(func);
        if(idx>=0){
            this.listeners.splice(idx,1);
        }
    }

    /*copyLayoutDataToState(state) {
        state.layoutData = this;
    }

    registerLayoutComponent(comp) {
        this.copyLayoutDataToState(comp.state);
        comp.updateLayout();
        this.listeners[comp] = true;
    }

    unregisterLayoutComponent(comp) {
        delete this.listeners[comp];
    }*/

}

export default new LayoutService();