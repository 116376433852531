import React, {useState} from "react";
import vertretungsDataService from "../DataSources/VertretungsDataService";

class EnterNumberDisplayAccessClass {
    ActivatePopup(termin) {

    }

    handleTouchEnd(ev, termin) {
        if (vertretungsDataService.baseAdminAccess)
        {
            console.log("touch end",this.startX,ev);
            if (this.activeTouch === termin && ev.changedTouches&&ev.changedTouches.length>0) {
                let delta = ev.changedTouches[0].clientX-this.startX;
                //const deltaTime = new Date().getTime() - this.startTime;
                if (delta >10) { // deltaTime >= 1000 && deltaTime <= 10000
                    EnterNumberDisplayAccess.ActivatePopup(this.activeTouch);
                }
            }
        }
    }

    handleTouchStart(ev, termin) {
        if (vertretungsDataService.baseAdminAccess)
        {

            this.startTime = new Date().getTime();
            this.startX = ev.touches[0].clientX;
            this.activeTouch = termin;
        }
    }
}

export const EnterNumberDisplayAccess = new EnterNumberDisplayAccessClass();
export default (props) => {
    const [adminEnterVisible, setAdminEnterVisible] = useState({
        visible: false,
        termin: null,
        termine: []
    });
    const [adminSelectedDay, setAdminSelectedDay] = useState(undefined);
    const [adminFinishedSuccess, setAdminFinishedSuccess] = useState(false);

    const numberClick = async () => {
        if (adminFinishedSuccess) {
            return;
        }
        let anzahl = parseInt(document.getElementById("anzahlEingeben").value);
        if (anzahl < 0 || anzahl > 500 || isNaN(anzahl)) {
            alert("keine gültige zahl");
            return;
        }
        let date = EnterNumberDisplayAccess.occurrences[adminSelectedDay];

        function formatDateForMariaDB(date) {
            // Hole das Jahr, den Monat und den Tag aus dem Date-Objekt
            const year = date.getFullYear();
            // getMonth() gibt die Monate von 0 bis 11 zurück, also müssen wir 1 hinzufügen
            const month = String(date.getMonth() + 1).padStart(2, '0');
            // getDate() gibt den Tag des Monats zurück
            const day = String(date.getDate()).padStart(2, '0');

            // Baue den String im Format YYYY-MM-DD zusammen
            return `${year}-${month}-${day}`;
        }

        date = formatDateForMariaDB(date);
        console.log("anzahl", date, adminEnterVisible.termin.permanentId, anzahl);
        try {
            let text = await (await fetch("https://tgbornheim.de/v4/?service=sport_termin_save_teilnehmer_count&termin_date=" + date + "&count=" + anzahl + "&termin_permanent_id=" + adminEnterVisible.termin.permanentId)).text();
            let json = JSON.parse(text);
            if (!json.success) {
                alert("zahl konnte nicht gespeichert werden!");
                return;
            }
        } catch (e) {
            console.error("error in storing anzahl", e);
            alert("zahl konnte nicht gespeichert werden!");
            return;
        }
        setAdminFinishedSuccess(true);
        setTimeout(() => {
            setAdminEnterVisible({visible: false});
            setAdminFinishedSuccess(false);
        }, 1000);

    };
    EnterNumberDisplayAccess.ActivatePopup = (termin) => {
        const day = termin.day;

        let today = new Date();
        const occurrences = [];
        EnterNumberDisplayAccess.occurrences = occurrences;
        let count = 0;

        while (count < 3) {
            if ((today.getDay() + 6) % 7 === day) {

                occurrences.push(today);
                count++;
            }
            today = new Date(today);
            today.setDate(today.getDate() - 1);
        }
        setAdminSelectedDay(0);
        setAdminEnterVisible({
            visible: true,
            termin: termin,
            termine: occurrences
        });
    };
    return <>
        {
            adminEnterVisible.visible &&
            <div className={"EnterNumberAdminPopup"} onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                setAdminEnterVisible({
                    visible: false,
                    termine: []
                });
                console.log("clicked");
            }}>
                <div className={"EnterNumberAdminContent"} onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                }}>
                    <div className={"EnterNumberHeader"}>
                        Teilnehmer Anzahl Eintragen {adminEnterVisible.termin?.timeSort}
                    </div>
                    <div className={"enterInfoLine"}>
                        <div className={"enterInfoText"}>
                            Teilnehmer Anzahl
                        </div>
                        <select value={adminSelectedDay} onChange={(v) => {
                            setAdminSelectedDay(v.target.value);
                        }}>
                            {
                                adminEnterVisible.termine.map((t, idx) => <option value={idx}>
                                    {t.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'})}
                                </option>)
                            }
                        </select>
                    </div>
                    <div className={"enterInfoLine"}>
                        <div className={"enterInfoText"}>
                            Anzahl
                        </div>
                        <input type={"number"} min={0} max={500}
                               onKeyPress={(ev) => {
                                   if (ev.code == "Enter") {
                                       numberClick();
                                   }
                               }}
                               autoFocus={true} id={"anzahlEingeben"}/>
                    </div>
                    {
                        adminFinishedSuccess &&
                        <div className={"EnterNumberHeader"}>
                            Vielen Dank für den Eintrag!
                        </div>
                    }
                    <button onClick={() => {
                        numberClick();
                    }}>Absenden
                    </button>
                </div>
            </div>
        }</>

}
