import React from "react";
import ReactModal from 'react-modal';
import LayoutService from "../Services/LayoutService";
import MainFlowManager from "../Services/MainFlowManager"

export default class SModalDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };


    }

    componentWillMount() {
        let that = this;
        this.handleFunc = function (data) {
            that.onFlowEvent(data);
        };
        MainFlowManager.onChange(this.handleFunc);
    }

    componentWillUnmount() {
        MainFlowManager.removeOnChange(this.handleFunc);
    }

    onFlowEvent(data) {
        this.setState({
            showModal: true,
            ...data
        });
    }

    render() {
        return (
            <ReactModal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onRequestClose={() => {
                    this.setState({
                        showModal: false
                    });
                }}
                className="Modal"
                overlayClassName="Overlay"
            >
                {
                    this.state.images &&
                    this.state.images.map((entry) =>
                        <img
                            height={LayoutService.fullContentHeight}
                            key={entry.image} onClick={() => {
                            this.setState({
                                showModal: false
                            });
                        }} src={entry.image}/>
                    )
                }
                {
                    <img
                        height={LayoutService.fullContentHeight}
                        key={this.state.image} onClick={() => {
                        this.setState({
                            showModal: false
                        });
                    }} src={this.state.image}/>
                }
            </ReactModal>
        )
    }

}
