import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
/*import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

Sentry.init({
    dsn: "https://8fc6520622454b82abc9b6dcb200218b@o474462.ingest.sentry.io/5510775",
    integrations: [new Integrations.BrowserTracing(),],
    // We recommend adjusting this value in production, or using tracesSampler // for finer control
    tracesSampleRate: 1.0,
});
*/
ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
