import React from "react";
import PageDataService from "../../DataSources/PageDataService"
import LayoutService from "../../Services/LayoutService";
import SMainPageLabel from "../../StyleComponents/SMainPageLabel";
import {Link} from "react-router-dom";

export default class VeranstaltungsDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            veranstaltungsList:[],
            loading: true,
        };
    }

    componentDidMount() {

        PageDataService.onChange(() => {
            this.didReceiveData = true;
            this.updateView(this.props);
        });

    }

    componentWillReceiveProps(nextProps) {

        //console.log('nextProps',nextProps);
        this.updateView(nextProps);
    }

    updateView(props) {
        if (this.didReceiveData) {
            this.state.veranstaltungsList = PageDataService.getVeranstaltungsList();
            this.state.loading = false;
            this.setState(this.state);
        }
    }


    render() {
        return (
            <div style={{
                width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                alignItems: 'flex-start',
                display: 'flex', flexDirection: 'column'
            }}>
                <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                    <SMainPageLabel text="Veranstaltungen"/>


                        {this.state.veranstaltungsList.map((ob) =>
                            <div className="bigshadow description"
                                 style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}
                            >
                            <Link key={ob.id} to={ob.link}>{ob.title}</Link>
                            </div>
                        )}


                </div>

            </div>
        )
    }
}
