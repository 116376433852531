
class AppSpecific {
    async initAppSecifics() {
        console.log("app Specific Web Preview");
        this.className = "web";
        this.allowAllStates = true;
        this.usePreviewData = true;
        this.isMobileDevice = false;
        this.mainPageBackendUrl = "https://www.tgbornheim.de/";
        this.mainPageBackendUrlV3 = "https://www.tgbornheim.de/v3/";
        this.requiresSpecialVideoTreatment = false;
    }
}
let appSpecific = new AppSpecific();
export default appSpecific;
