import React from "react";
import LayoutService from "../../Services/LayoutService"
import SMainPageLabel from "../../StyleComponents/SMainPageLabel"
import SpecialPageDisplay from "../SpecialPageDisplay";
import SettingsService from "../../DataSources/SettingsService"

export default class KontaktDisplay extends React.Component {

    constructor() {
        super();


        var images = [];

        for (let i = 1; i <= 8; i++) {
            images.push({img: SettingsService.getMainPageUrl()+"img/fitness/" + i + "_800.jpg"});
            // (())            img.setValue("srcBig", "http://www.tgbornheim.de/img/fitness/" + i + "_800.jpg");
            // images.pushChild(img);
        }
        this.state = {
            images: images
        };
    }

    render() {
        return (
            <SpecialPageDisplay title="Info / Kontakt" slug="info-kontakt"/>
        )
    }
}
