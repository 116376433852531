import React from "react";

export default class SMainPageLabel extends React.Component {

    render() {
        return (
            <div className="mainPageLabel" style={{marginTop: 10}}>
                {this.props.text}
            </div>
        )
    }

}
