
class AppSpecific {
    async initAppSecifics() {
        console.log("app Specific Web");
        this.className = "web";
        this.allowAllStates = false;
        this.usePreviewData = false;
        this.isMobileDevice = false;
        this.mainPageBackendUrl = "https://www.tgbornheim.de/";
        this.mainPageBackendUrlV3 = "https://www.tgbornheim.de/v3/";
        this.requiresSpecialVideoTreatment = false;
    }
}
let appSpecific = new AppSpecific();
export default appSpecific;
