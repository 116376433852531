import React from "react";
import SportService from "../DataSources/SportService"
import StdContentDisplay from "./StdContentDisplay"


export default class SportArtDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            sportArt: {},
            sportcenters: [],
            categories: [],
            termine: [],
            angebote: []
        };

    }

    componentDidMount() {
        SportService.onChange(() => {
            this.state.sportArt = SportService.sportArtById[this.props.match.params.id];
            this.state.description = this.state.sportArt.description;
            this.setState(this.state);



            setTimeout(()=>{
                this.state.termine = SportService.findTerminByFullQueryAndSetCurrentFilter({
                    sportArtFilter: this.state.sportArt.id
                });

                this.state.sportcenters = SportService.findRelatedSportCentersForTermine(this.state.termine);

                this.state.categories = SportService.categorizeByAngebot(this.state.termine);


                this.state.loading=false;
                this.setState(this.state);
            },1);

        });
    }

    render() {
        return (
            <StdContentDisplay description={this.state.description} name={this.state.sportArt.name}
                               header={this.state.sportArt.name}
                               image={this.state.sportArt.img}
                               loading={this.state.loading}
                               categories={this.state.categories}
                               lists={[{
                                   baseLink: "/sportcenter/",
                                   header: 'Orte',
                                   list: this.state.sportcenters
                               }]}
            />
        )
    }
}