import React from "react";
import LayoutService from "../Services/LayoutService"
import ImageListWithText from "./ImageListWithText";
import {Link, Route} from "react-router-dom";
import Slider from "react-slick";
import ReactModal from 'react-modal';
import TimeBoxDisplay from "./TimeBoxDisplay";
import SportService from "../DataSources/SportService";
import TgbDataSource from "../DataSources/TgbDataSource";
import PageDataService from "../DataSources/PageDataService";
import StyleService from "../PlatformSpecific/StyleService";

import SMainPageLabel from "../StyleComponents/SMainPageLabel"
import SImgWithShadow from "../StyleComponents/SImgWithShadow"
import SMainPageLabelWithBottomMargin from "../StyleComponents/SMainPageLabelWithBottomMargin"
import MainFlowManager from "../Services/MainFlowManager"
import SFullContentPage from "../StyleComponents/SFullContentPage";
import SRow100Percent from "../StyleComponents/SRow100Percent"
import SRowFullContentWidth from "../StyleComponents/SRowFullContentWidth"
import SLeftContent from "../StyleComponents/SLeftContent"
import SImageShowSlider from "../StyleComponents/SImageShowSlider";
import AppSpecifics from "../AppSpecific";

import moment from "moment"

export default class HomeDisplay extends React.Component {

    constructor() {
        super();
        this.upcomingStreams = [];
        this.state = {
            newsList: [],
            instagramList: [],
            angebotList: [],
            sportstaetteList: [{
                id: 'scb',
                img: "res/main_scb.png",
                title: "Sportcenter Bornheim",
                name: "SCB"
            }, {
                id: 'tgb',
                img: "res/main_tgb.png",
                title: "Berger Straße",
                name: "Berger Straße"
            }, {
                id: 'asf',
                img: "res/main_asf.png",
                title: "Aqua Sports Fun",
                name: "Aqua Sports Fun"
            }, {
                id: 'studio',
                img: "res/main_studio.png",
                title: "Fitness Studios",
                name: "Fitness Studios"
            }, {
                id: 'extern',
                img: "res/main_extern.png",
                title: "Externe Sportstätten",
                name: "Externe",
            }]
        };
        PageDataService.onChange(() => {

            this.state.newsList = PageDataService.getNews();

            this.mainPageContentBase = PageDataService.getMainPageContent();
            this.state.mainPageContent = this.mainPageContentBase;
            try {
                this.upcomingStreams=[];
                let page = PageDataService.getPageById(3149);
                //console.log("my page found", page);
                if (page && page.content && page.content.rendered) {
                    //console.log("my page found", page.content.rendered);
                    let splitted = page.content.rendered.split("<h2>");
                    splitted.forEach((txt) => {
                        try {
                            if (txt.indexOf("-") !== -1 && txt.indexOf(":") !== -1 && txt.indexOf("figure") !== -1) {
                                // 10:30-10:40 Uhr 13.11.2020 - Test1
                                let uhrIndex = txt.toLowerCase().indexOf("uhr");
                                let timeTxtSplitted = txt.substr(0, uhrIndex).split("-");
                                let from = timeTxtSplitted[0].trim();
                                let to = timeTxtSplitted[1].trim();
                                let rest = txt.substr(uhrIndex + 3).trim();
                                let restAfterDayIdx = rest.indexOf(" ");
                                let dayTxt = rest.substr(0, restAfterDayIdx);
                                rest = rest.substr(restAfterDayIdx + 1).trim();
                                //console.log("splitted rest", rest);

                                let title = rest.substr(rest,rest.indexOf("</h2")).trim();
                                //console.log("splitted title", title);
                                let figure = rest.substr(rest.indexOf("<figure"));
                                figure = figure.substr(0, figure.indexOf("</figure>") + 9);
                                //console.log("splitted day", dayTxt);
                                //console.log("splitted figure", figure);

                                let fromTime = moment(dayTxt + " " + from, 'DD.MM.YYYY HH:mm');
                                let toTime = moment(dayTxt + " " + to, 'DD.MM.YYYY HH:mm');
                                //console.log("splitted fromTime", fromTime.format(), dayTxt + " " + from);
                                //console.log("splitted toTime", toTime.format(), dayTxt + " " + to);
                                let entry = {
                                    title:title,
                                    fromTime: fromTime,
                                    toTime: toTime,
                                    figure: figure
                                };
                                this.upcomingStreams.push(entry);
                                //console.log("splitted entry",from," TO ",to,">","day",dayTxt,"::",figure,"REST",rest,"ori",txt);
                            }
                        }catch (e) {
                            console.error("upcomingstreams could not load entry",txt, e);
                        }
                    });
                }
                this.findUpcomingStream(false);

                if(!this.videoIntervalId){
                    this.videoIntervalId = setInterval(()=>{
                        this.findUpcomingStream(true);
                    }, 60000);
                }

            } catch (e) {
                console.error("upcomingstreams could not load video", e);
            }
            this.setState(this.state);

        });


        this.onNewsChange = (data) => {
            if (data && data.instagram) {

                data.instagram.forEach((item) => {
                    item.img = item.previewImg;
                });
                this.state.instagramList = data.instagram;
                this.setState(this.state);
            }
        };
        this.onSportChange = () => {
            console.log("onchange");
            this.state.angebotList = SportService.angebotList;
            this.state.fullData = SportService.findTerminByFullQueryAndSetCurrentFilter({});
            this.setState(this.state);
        };

        this.updateDimensions();


    }
    findUpcomingStream(doSetState){
        let bestBefore=null;
        let current=null;
        //this.upcomingStream
        let now = moment();
        let isCurrentyRunning = false;
        this.upcomingStreams.forEach((entry)=>{

            if(entry.fromTime.isAfter(now)) {
                if(!bestBefore){
                    bestBefore = entry;
                }else
                if(entry.fromTime.isBefore(bestBefore)){
                    bestBefore = entry;
                }
            }
            if(entry.fromTime.isBefore(now) && entry.toTime.isAfter(now)){
                current = entry;
                isCurrentyRunning = true;
            }
        },);
        if(!current){
            current = bestBefore;
        }
        let didSomethingChanged = false;
        if(this.state.isCurrentyRunning !==isCurrentyRunning){
            this.state.isCurrentyRunning = isCurrentyRunning;
            didSomethingChanged = true;
            console.log("upcomingstream current stream is live ",isCurrentyRunning);
        }
        if(this.state.upcomingStream!==current){
            this.state.upcomingStream = current;
            didSomethingChanged = true;
            console.log("upcomingstream current stream changed to ",isCurrentyRunning,current);
        }
        try {
            if (didSomethingChanged) {
                if (this.mainPageContentBase) {
                    let txt = this.mainPageContentBase;
                    let beforeIframe = txt.substr(0,txt.indexOf("<iframe"));
                    let afterIframe = txt.substr(txt.indexOf("</iframe")+9);
                    if(this.state.upcomingStream){
                        let nextStreamHtml="";
                        nextStreamHtml+="<h2>";

                        if(this.state.isCurrentyRunning){
                            nextStreamHtml+="LIVE<br>";
                        }else{
                            nextStreamHtml+="nächster Stream<br>";
                        }
                        nextStreamHtml+=this.state.upcomingStream.title+"<br>";
                        nextStreamHtml+=this.state.upcomingStream.fromTime.format("DD.MM.YY")+" ";
                        nextStreamHtml+=this.state.upcomingStream.fromTime.format("HH:mm");
                        nextStreamHtml+=" - ";
                        nextStreamHtml+=this.state.upcomingStream.toTime.format("HH:mm")+" Uhr";
                        nextStreamHtml+="</h2>";
                        if(AppSpecifics.requiresSpecialVideoTreatment) {
                            nextStreamHtml+="<font color=red>Videos müssen in App Noch eingebaut werden.</font>";
                        }else{
                            nextStreamHtml+=this.state.upcomingStream.figure;
                        }
                        this.state.mainPageContent = beforeIframe+nextStreamHtml+afterIframe;
                    }else{
                        if(AppSpecifics.requiresSpecialVideoTreatment){
                            this.state.mainPageContent = beforeIframe+""+afterIframe;
                        }
                    }

                    //console.log("upcomingstream mainPageContentBase ", this.mainPageContentBase);
                    //this.state.mainPageContent
                }
            }
        }catch (e) {
            console.error('error in merging content',e);
        }
        if(didSomethingChanged && doSetState){


            this.setState(this.state);
        }
    }
    updateDimensions() {

        this.state.newsItemWidth = 160 + 20;
        this.state.newsItemCount = Math.max(1, LayoutService.leftWidth / 150);
        console.log("this.state.newsItemCount", this.state.newsItemCount);

        this.state.instagramCount = Math.max(1, LayoutService.leftWidth / 210);


        let sportAngebotWidth = 230;
        this.state.sportAngebotCount = Math.max(1, Math.round(LayoutService.leftWidth / sportAngebotWidth));

        let sportstaetteWidth = Math.min(420, Math.max(0, LayoutService.fullContentWidth / 5 - 4));

        this.state.sportstaetteWidth = sportstaetteWidth;
        sportstaetteWidth += 100;
        this.state.sportstaetteCount = Math.min(1, Math.max(2, Math.round(LayoutService.leftWidth / sportstaetteWidth)));


    }

    componentWillMount() {
        let that = this;

        this.updateFunc = function () {
            that.updateDimensions();
        };

        TgbDataSource.onChange(this.onNewsChange);
        SportService.onChange(this.onSportChange);
        LayoutService.onDimensionChange(this.updateFunc);

    }

    componentWillUnmount() {
        if(this.videoIntervalId){
            clearInterval(this.videoIntervalId);
        }
        TgbDataSource.removeOnChange(this.onNewsChange);
        SportService.removeOnChange(this.onSportChange);
        LayoutService.removeOnChange(this.updateFunc);
    }

    render() {
        return (
            <SFullContentPage>
                <div className="bigshadow description" style={{padding: 10, marginTop: 40}}>
                    <div dangerouslySetInnerHTML={{__html: this.state.mainPageContent}}></div>
                </div>
                <SMainPageLabelWithBottomMargin text="Sportstätten"/>
                {
                    LayoutService.rightWidth <= 10 &&
                    <div style={{width: LayoutService.leftWidth}}>
                        <ImageListWithText sliderWidth={LayoutService.leftWidth} baselink="/group/"
                                           itemHeight={51} itemCalcWidth={110} itemWidth={105}
                                           list={this.state.sportstaetteList}/>
                    </div>
                }
                {
                    LayoutService.rightWidth > 10 &&
                    <SRow100Percent>
                        {
                            this.state.sportstaetteList.map((ob) =>
                                <div key={ob.id}>
                                    <div style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: 'flex'
                                    }}>
                                        <Link to={"/group/" + ob.id}>
                                            <div className="bigshadow" style={{
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                display: 'flex',

                                                alignItems: 'stretch',
                                                justifyContent: 'center',
                                                flexDirection: 'column'

                                            }}>
                                                <img width={this.state.sportstaetteWidth} src={ob.img}
                                                     style={{
                                                         borderTopLeftRadius: 10,
                                                         borderTopRightRadius: 10
                                                     }}/>
                                                <div className="imageTextLine" style={{
                                                    width: this.state.sportstaetteWidth
                                                }}>
                                                    {ob.title}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        }
                    </SRow100Percent>
                }


                <SRowFullContentWidth style={{
                    width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    display: 'flex', flexDirection: 'row'
                }}>
                    <SLeftContent>

                        <SMainPageLabel text={"Sportangebot"}/>

                        <ImageListWithText sliderWidth={LayoutService.leftWidth} baselink="/angebot/"
                                           itemHeight={120} itemCalcWidth={190} itemWidth={180}
                                           list={this.state.angebotList}/>
                        {
                            LayoutService.rightWidth <= 10 &&
                            <TimeBoxDisplay limitLines={true} fullData={this.state.fullData}
                                            width={LayoutService.leftWidth}/>

                        }


                        <SMainPageLabel text={"News"}/>

                        <SImageShowSlider images={this.state.newsList} slidesToShow={this.state.newsItemCount}
                                          itemWidth={this.state.newsItemWidth}
                                          onImgClick={(ob) => {
                                              MainFlowManager.showModalImage(ob.img);
                                          }}/>

                        <SMainPageLabel text={"Instagram"}/>

                        <SImageShowSlider images={this.state.instagramList} slidesToShow={this.state.instagramCount}
                                          itemWidth={this.state.newsItemWidth}
                                          onImgClick={(ob) => {
                                              if (this.isInternDisplay) {
                                                  MainFlowManager.showModalImageList(ob.images);
                                              } else {
                                                  window.location.href = ob.url;
                                              }
                                          }}/>

                    </SLeftContent>

                    {
                        LayoutService.rightWidth > 10 &&
                        <div style={{marginTop: 40}}>

                            <TimeBoxDisplay fullData={this.state.fullData}
                                            width={LayoutService.rightContentWidth}/>
                            <div className="smallshadow"
                                 style={{
                                     width: LayoutService.rightContentWidth,
                                     maxWidth: LayoutService.rightContentWidth,
                                     backgroundColor: "#ffffff",
                                     padding: 14,
                                     marginTop: 80,
                                     borderRadius: 10,
                                     display: 'flex',
                                     justifyContent: 'space-around',
                                     alignItems: 'center'
                                 }}>
                                <a href="https://www.facebook.com/TGBornheim" target="_blank"><img
                                    width="40px"
                                    src="res/facebook_logo.png"></img></a>
                                <a href="https://www.instagram.com/tgbornheim/" target="_blank"><img
                                    width="40px" src="res/instagram_logo.png"></img></a>
                            </div>
                            <div className="smallshadow"
                                 style={{
                                     width: LayoutService.rightContentWidth,
                                     maxWidth: LayoutService.rightContentWidth,
                                     backgroundColor: "#ffffff",
                                     padding: 14,
                                     marginTop: 10,
                                     borderRadius: 10,
                                     display: 'flex',
                                     flexDirection: 'column',
                                     justifyContent: 'stretch',
                                     alignItems: 'stretch'
                                 }}>

                                <div style={{
                                    backgroundColor: "#ffffff",
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <a href="https://www.vgf-ffm.de/" target="_blank"><img height="90px"
                                                                                           src="res/vgf_button_tgborn.gif"
                                                                                           style={{borderRadius: 5}}></img></a>
                                    <a href="https://www.initiative-gesunde-unternehmen.de/"
                                       target="_blank"><img height="90px"
                                                            src="res/logo_gesundeUnternehmen.png"></img></a>
                                </div>
                                <a style={{marginTop: 10}} href="https://www.frankfurt-main.ihk.de/"
                                   target="_blank"><img width="100%" src="res/logo_wirbildenaus.jpg"></img></a>
                                <div style={{
                                    marginTop: 10,
                                    backgroundColor: "#ffffff",
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <a href="https://www.akademie-sport-gesundheit.de/" target="_blank"><img
                                        height="65px" src="res/logo_akademie_sport_gesundheit.jpg"
                                        style={{borderRadius: 5}}></img></a>
                                    <a href="https://www.sportjugend-hessen.de/freiwilligendienste/"
                                       target="_blank"><img width="65px"
                                                            src="res/logo_sportjugendhessen.png"></img></a>

                                    <a href="https://sportprogesundheit.dosb.de/projekte-und-initiativen/sport-pro-fitness/"
                                       target="_blank"><img height="65px"
                                                            src="res/logo_sport_pro_fitness.jpg"></img></a>

                                </div>
                            </div>


                        </div>
                    }
                </SRowFullContentWidth>

            </SFullContentPage>

        )
    }
}
