import React from "react";
import LayoutService from "../Services/LayoutService";

export default class SRow extends React.Component {

    render() {
        return (
            <div style={{
                width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                alignItems: 'flex-start',
                display: 'flex', flexDirection: 'row'
            }}>
                {this.props.children}
            </div>
        )
    }

}
