import React from "react";
import LayoutService from "../../Services/LayoutService"
import SMainPageLabel from "../../StyleComponents/SMainPageLabel"
import SpecialPageDisplay from "../SpecialPageDisplay";

export default class UeberDisplay extends React.Component {

    constructor() {
        super();
    }

    render() {
        return (
            <SpecialPageDisplay title="Über die TGB" slug="info-ueber"/>
        )
    }
}