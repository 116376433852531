import React from "react";
import LayoutService from "../../Services/LayoutService";
import SMainPageLabel from "../../StyleComponents/SMainPageLabel";

import {view, store} from '@risingstack/react-easy-state';
import moment from "moment";
import SettingsService from "../../DataSources/SettingsService";


const reservationData = store({
    selectedPlaceId: null,
    placeData: {
        "-1": {
            text: "Indoorspielen in der Halle"
        },
        "-2": {
            text: "Indoorspielen im Gym 2"
        },
        "-3": {
            text: "Indoorspielen im Gym 3"
        },
        "-4": {
            text: "Studio Falltorstr."
        },
        "-5": {
            text: "Studio Sportcenter"
        },
        "-6": {
            text: "Tischtennis Gym 1"
        },
        "-7": {
            text: "Badminton Halle SCB"
        },
        "-8": {
            text: "Kletterwand SCB"
        },
        "-9": {
            text: "Tischtennis Saalbau"
        },
        "-10": {
            text: "Eltern & Kind Turnen 1-3"
        },
        "-11": {
            text: "Familienturnen 1-6"
        }


    },
    currentReservation: null
});
const urlParams = new URLSearchParams(window.location.search);
let sportIds = [];
let sportIdQuery = '';

let usedPlaceList = [];
for (let i = 1; i <= 20; i++) {
    if (urlParams.get('sport' + i)) {
        let sportId = parseInt(urlParams.get('sport' + i));

        if (sportId !== 0 && !isNaN(sportId)) {
            sportIds.push(sportId);
            sportIdQuery += '&sport' + i + "=" + sportId;
            let placeEntry = {
                sportId: sportId,
                placeData: reservationData.placeData[sportId + ''],
                hourlyEntries: {}
            };
            reservationData.placeData[sportId + ''].placeEntry = placeEntry;
            usedPlaceList.push(placeEntry);
        }
    }
}

reservationData.usedPlaceList = usedPlaceList;
console.log("sportIdQuery", sportIdQuery);
reservationData.activeHours = [moment().hour()-1,moment().hour(), moment().hour() + 1, moment().hour() + 2];

const updateBookingListState = () => {
    reservationData.activeHours = [moment().hour()-1,moment().hour(), moment().hour() + 1, moment().hour() + 2];
    fetch(SettingsService.getDataUrlNew() + "?service=terminbuchungen_admin_list_v2&time=" + (new Date().getTime()) + sportIdQuery)
        .then((response) => {
            return response.json();
        })
        .then((responseJson) => {
            reservationData.usedPlaceList.forEach((placeEntry) => {
                placeEntry.hourlyEntries = {};
            });
            if (responseJson.array) {
                responseJson.array.forEach((entry) => {
                    if (entry.sport_id) {


                        const placeEntry = reservationData.placeData[entry.sport_id + ''].placeEntry;

                        entry.booking_time = moment(entry.booking_time, 'YYYY-MM-DD hh:mm');

                        const hour = entry.booking_time.hour() + '';
                        if (!placeEntry.hourlyEntries[hour]) {
                            placeEntry.hourlyEntries[hour] = [];
                        }
                        placeEntry.hourlyEntries[hour].push(entry);
                    }
                });
            }
            console.log("reservationData", reservationData);

        })
        .catch((error) => {
            console.error('error in loading reservation data', error);
        });
}

let wasInitialized = false;
let init=()=>{
    setInterval(() => {
        updateBookingListState();
    }, 60000 * 5);
    updateBookingListState();

};
export default view((props) => {

    if(!wasInitialized){
        wasInitialized = true;
        init();
    }

    /*const doReservation = (deleteWish, termin) => {
        let barcode = reservationData.lastBarcode;
        let geburtsDatum = Globals.geburtsDatum;
        fetch(SettingsService.getDataUrlNew() + "?service=terminbuchungen_book_v2&geburtstag=" + geburtsDatum + "&barcode=" + barcode + "&delete=" + deleteWish + "&termin=" + termin + "&sport=" + reservationData.selectedPlaceId + "&time=" + (new Date().getTime()))
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                console.log("doReservationResponse", responseJson);
                updateBookingState();
            })
            .catch((error) => {
                console.error('error in loading reservation data', error);
            });
    };

    if (reservationData.lastBarcode !== myBarcode && myBarcode) {

        reservationData.lastBarcode = myBarcode;
        updateBookingState();
    }
*/

    const changeWasThere = (entry, was_there) => {
        const barcode = entry.barcode;
        const termin = entry.booking_time.format('YYYY-MM-DD H') + ':00';
        const sportId = entry.sport_id;
        fetch(SettingsService.getDataUrlNew() + "?service=terminbuchungen_admin_was_there&barcode=" + barcode + "&was_there=" + was_there + "&termin=" + termin + "&sport=" + sportId + "&time=" + (new Date().getTime()))
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                console.log("doReservationResponse", responseJson);
                updateBookingListState();
            })
            .catch((error) => {
                console.error('error in loading reservation data', error);
            });
    };
    let layoutState = {};
    layoutState.leftWidth = 130;

    let width = LayoutService.fullContentWidth - 60;
    layoutState.dayWidth = (width - 20) / 8;
    layoutState.leftWidth = layoutState.dayWidth + 20;


    return <div style={{
        width: LayoutService.fullContentWidth, justifyContent: 'space-between',
        alignItems: 'flex-start',
        display: 'flex', flexDirection: 'column'
    }}>
        <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

            <div className="bigshadow reservation-admin-barcode-list-container"
                 style={{backgroundColor: '#ffffff', padding: 20, minHeight: 50}}>

                {
                    <div className="reservation-admin-barcode-list">
                        <div>
                            Suche:
                            <div>
                                <input onChange={(inputEvent) => {
                                    reservationData.hintText = inputEvent.target.value;
                                }}/>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th></th>
                                {
                                    reservationData.usedPlaceList.map((place) =>
                                        <th>
                                            {place.placeData.text}
                                        </th>
                                    )
                                }
                            </tr>
                            {
                                reservationData.activeHours.map((hour) =>
                                    <tr>
                                        <td>
                                            {hour} Uhr
                                        </td>
                                        {
                                            reservationData.usedPlaceList.map((place) =>
                                                <td>
                                                    {
                                                        place.hourlyEntries[hour] &&
                                                        <div className="hour-container">
                                                            {
                                                                place.hourlyEntries[hour].map((entry) =>
                                                                    <div className={
                                                                        "hour-entry"
                                                                        +
                                                                        (entry.was_there === 1 ? ' was_there' : '')
                                                                        +
                                                                        ((entry.barcode.indexOf(reservationData.hintText) !== -1 && reservationData.hintText.length > 1) ? ' highlight' : '')
                                                                    }
                                                                         onClick={() => {
                                                                             changeWasThere(entry,entry.was_there=='1'?'0':'1');
                                                                         }}
                                                                    >
                                                                        {entry.barcode}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )

                            }

                        </table>
                    </div>
                }

            </div>
        </div>

    </div>;
});