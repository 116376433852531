export default (props) => {

    return <div className={"inputContainer "+((props.error?.length > 0)?"error":"")}>
        {props.children}
        {
            ((props.error?.length > 0)) &&
            <div className={"errorText"}>
                {props.error}
            </div>
        }
    </div>
}