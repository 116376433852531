import {store} from "@risingstack/react-easy-state";

const allowedUnfinished = false;

let globalStore = store({
    vertretungsDatenNum: 0
});

class VertretungsDataService {

    constructor() {
        this.vertretungsHashByPermaId = {};
        this.globalStore = globalStore;
        this.baseAdminAccess=false;
    }

    static convertGermanDate(dateText) {
        const [day, month, year] = dateText.split('.');

        let date = new Date(`${month}/${day}/${year}`);
        date.setHours(0,0,0,0);
        return date;
    }

    init() {
        this.updateVertretungsDaten();
        setInterval(() => {
            this.updateVertretungsDaten();
        }, 60000 * 5);
    }

    getVertretungsDatenByPermaId(id) {
        return this.vertretungsHashByPermaId[id];
    }

    static convertToSortableString(inputDateString) {
        const [tag, monat, jahr] = inputDateString.split('.');
        const outputDateString = [jahr, monat.padStart(2, '0'), tag.padStart(2, '0')].join('.');

        return outputDateString;
    }

    async updateVertretungsDaten() {
        try {
            let text = await (await fetch("https://www.tgbornheim.de/v4/?service=app_vertretungen")).text();
            let json = JSON.parse(text);
            this.baseAdminAccess=false;
            if(json.baseAdminAccess){
                this.baseAdminAccess=true;
            }
            let dateIn7Days = new Date();
            dateIn7Days.setDate(dateIn7Days.getDate() + 6);
            dateIn7Days.setHours(0, 0, 0, 0);
            let currentDay = new Date();
            //currentDay.setDate(0);
            currentDay.setHours(0, 0, 0, 0);

            json.content.forEach((v, idx) => {
                let date = VertretungsDataService.convertGermanDate(v.datum);

                if (date.getTime() >= currentDay.getTime() && date.getTime() < dateIn7Days.getTime()) {
                    let weekday = v.data;
                    let changes = [];
                    if (weekday.canceled) {
                        changes.push({type: "canceled"});
                        v.canceled = true;
                    } else {
                        if (weekday.uhrzeitActive && weekday.uhrzeit) {
                            changes.push({type: "uhrzeit", value: weekday.uhrzeit});
                            v.uhrzeit = weekday.uhrzeit;
                        }
                        if (weekday.trainerActive && weekday.trainer) {
                            changes.push({type: "trainer", value: weekday.trainer});
                            v.trainer = weekday.trainer;
                        }
                        if (weekday.ortActive && weekday.ort) {
                            changes.push({type: "ort", value: weekday.ort});
                            v.ort = weekday.ort;
                        }
                    }
                    if (weekday.freitextActive && weekday.freitext) {
                        changes.push({type: "freitext", value: weekday.freitext});
                        v.freitext = weekday.freitext;
                    }
                    if (changes.length > 0 && (v.data.finished || allowedUnfinished)) {
                        v.changes = changes;

                        if (!this.vertretungsHashByPermaId[v.terminId]) {
                            this.vertretungsHashByPermaId[v.terminId] = [];
                        }
                        this.vertretungsHashByPermaId[v.terminId].push(v);
                        if (this.vertretungsHashByPermaId[v.terminId].length > 0) {
                            // only the next one
                            this.vertretungsHashByPermaId[v.terminId].sort((a, b) => {
                                return VertretungsDataService.convertToSortableString(a.datum).localeCompare(VertretungsDataService.convertToSortableString(b.datum));
                            });
                            this.vertretungsHashByPermaId[v.terminId] = [this.vertretungsHashByPermaId[v.terminId][0]];
                        }

                    }
                } else {
                    if (v.datum === "19.3.2023") {
                        console.log("wtfisgoingOn", v.datum, v, date, currentDay,
                            "test1", date.getTime() >= currentDay, "test2", date.getTime() < dateIn7Days.getTime());
                    }

                }
            });
            globalStore.vertretungsDatenNum++;
            console.log("received vertretungsdaten2", this.vertretungsHashByPermaId);
        } catch (e) {
            console.error("could not update vertretungsdaten", e);
        }

    }
}


export default new VertretungsDataService();