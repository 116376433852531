import TimeHelper from "../Services/TimeHelper";

import PageDataService from "./PageDataService"
import React from "react";
import SettingsService from "./SettingsService"

class SportService {


    constructor() {
        this.isLoaded = false;
        this.changeEvent = [];
        this.rowIds = 0;
    }

    clear() {
        this.sportCenterById = {};
        this.sportArtById = {};
        this.trainersById = {};
        this.angebotById = {};

        this.sportArtList = [];
        this.sportCenterList = [];
        this.angebotList = [];

        this.sportCenterGroupHashMap = {};
        this.liveCount = 0;
    }

    updateFromDataAfterPageDataLoaded(sportData) {
        this.clear();


        let sportcenters = sportData["sportcenter"];

        for (let key in sportcenters) {

            let child = sportcenters[key];

            let sportCenter = {};
            sportCenter.name = child.name;
            sportCenter.nameLower = child.name.toLowerCase();
            sportCenter.id = parseInt(key);
            sportCenter.img = child.img;
            sportCenter.address = child.address;
            sportCenter.description = child.description;
            let groupId = parseInt(child.sportcentergroup);
            if (groupId === 2) {
                if (!(sportCenter.id === 4 || sportCenter.id === 22 || sportCenter.id === 41 || sportCenter.id === 2 || sportCenter.id === 3 || sportCenter.id === 57 || sportCenter.id === 1)) {
                    groupId = 1000; // special handling because of old and new website..
                }
            }
            sportCenter.sporcenterGroupId = groupId;
            sportCenter.lat = child.lat;
            sportCenter.longitude = child.long;
            this.sportCenterById[key] = sportCenter;

            this.sportCenterList.push(sportCenter);
        }

        this.sportCenterList.sort((o1, o2) => {
            return o1.name.localeCompare(o2.name);
        });

        let trainers = sportData["trainers"];

        for (let key in trainers) {
            let child = trainers[key];

            let trainer = {};
            trainer.id = parseInt(key);

            let name = child["name"];
            if (name && name.length > 0) {
                trainer.name = name;
                this.trainersById[key] = trainer;
            } else {
                trainer.name = "";
            }
            trainer.nameLower = trainer.name.toLowerCase();

        }

        let sportarten = sportData["sportarten"];

        var terminId = 1;

        for (let key in sportarten) {
            let sportArt = {};
            sportArt.id = parseInt(key);
            sportArt.termine = [];
            sportArt.angebote = [];
            sportArt.angeboteHash = {};
            let child = sportarten[key];

            let name = child["name"];

            if (name && name.length > 0) {
                sportArt.name = name;
            } else {
                sportArt.name = "";
            }
            sportArt.nameLower = sportArt.name.toLowerCase();

            let description = child["description"];

            if (description && description.length > 0) {
                sportArt.description = description;
                sportArt.descriptionLower = description.toLowerCase();
            }

            let termine = child["termine"];
            if (termine) {
                termine.forEach((termin) => {

                    let terminDay = termin["day"];
                    let start = termin["start"];
                    let ende = termin["ende"];


                    let actualTermin = {};
                    actualTermin.id = terminId++;
                    actualTermin.day = parseInt(terminDay);
                    actualTermin.dayText = TimeHelper.getDayText(actualTermin.day);
                    actualTermin.start = parseInt(start);
                    actualTermin.ende = parseInt(ende);
                    actualTermin.sportArtId = sportArt.id;
                    actualTermin.sportArtName = name;
                    actualTermin.sportArt = sportArt;
                    actualTermin.trainer = this.trainersById[termin["trainer"]];
                    if (actualTermin.trainer) {
                        actualTermin.trainerName = actualTermin.trainer.name;
                    } else {
                        actualTermin.trainerName = "";
                    }
                    actualTermin.sportcenter = this.sportCenterById[termin["sportcenter"]];

                    actualTermin.startString = TimeHelper.getTimeStr(start);
                    actualTermin.endeString = TimeHelper.getTimeStr(ende);
                    actualTermin.vonBis = actualTermin.startString + " - " + actualTermin.endeString;

                    actualTermin.timeSort = actualTermin.sportArt.nameLower + " " + terminDay + " " + actualTermin.vonBis;

                    actualTermin.permanentId =
                        actualTermin.sportArtId
                        + '_' + actualTermin.day
                        + '_' + actualTermin.start
                        + '_' + termin["sportcenter"];

                    if (actualTermin.sportcenter) {

                        sportArt.termine.push(actualTermin);
                    } else {
                        console.error("termin sportcenter not found " + sportArt.name + " " + termin.sportcenter);
                    }
                });

                this.sportArtById[key] = sportArt;
                this.sportArtList.push(sportArt);

            }

        }

        let angebote = sportData["sportangebot"];

        for (let key in angebote) {

            let child = angebote[key];

            let name = child["name"];

            if (name && name.length > 0) {
                let img = SettingsService.getMainPageUrl()+"img/angebot/" + key + "_200.jpg";
                let sportAngebot = {};
                sportAngebot.id = parseInt(key);
                sportAngebot.name = name;
                sportAngebot.img = img;
                sportAngebot.description = PageDataService.getSportAngebotDescription(name);
                sportAngebot.orderId = child["orderid"];
                this.angebotList.push(sportAngebot);
                this.angebotById[sportAngebot.id] = sportAngebot;
            }

//            abtList.sort((o1, o2) -> o1.tryGetIntChild("orderid") - o2.tryGetIntChild("orderid"));
        }
        this.angebotList.forEach((o1, o2) => {
            return o1.orderId - o2.orderId;
        });


        this.initSportCenterGroups();
        for (let key in this.sportCenterGroupHashMap) {
            var g = this.sportCenterGroupHashMap[key];


            if (g.slug) {
                g.description = PageDataService.getSportStaettenDescription(g.slug) + g.descriptionFixed;
            }

        }
        this.initSportAngebotMappings(sportData);

        this.updateLiveCount();

        this.isLoaded = true;
        this.changeEvent.forEach((evt) => {
            evt();
        });
    }

    removeHandler(func) {
        this.changeEvent = this.changeEvent.filter((value, index, arr) => {
            return value !== func;
        });

    }

    updateLiveCount() {
        this.liveCount = 0;
    }

    initSportCenterGroups() {
        let g = {
            id: 1,
            shortId: "scb",
            fullText: 'Sportcenter Bornheim',
            slug: "sportcenter bornheim",
            text: "Sportcenter Bornheim",
            description: "<b>Sportcenter Bornheim</b><br>" +
                "Inheidener Str. 64<br>\n" +
                "60385 Frankfurt Main<br>\n" +
                "<br>\n" +
                "Tel. 069 9450720<br>\n" +
                "<br><br>\n"
            ,
            descriptionFixed: "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_showbelegplan2&id=16&.pdf')\" class='downloadLink'>Belegplan</a>" +
                "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_sportgroupdaily&id=1&inc1=23&inc2=11&inc3=27&inc4=95&inc5=88&inc6=92&inc7=91&inc8=89&.pdf')\" class='downloadLink'>Hallen Belegung 1</a>"
                +
                "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_sportgroupdaily&id=1&inc1=90&inc2=93&inc3=94&inc4=31&inc5=158&inc6=159&inc7=160&inc8=161&inc9=162&.pdf')\" class='downloadLink'>Hallen Belegung 2</a>",
            img: "main_scb",
            fullImage: "res/full_scb.png",
            fullImageLink:"https://my.matterport.com/show/?m=7aMk2f5sWLa"
        };

        // location
        /*g.places=[new SportCenterGroupPlaceDescription(new Location(50.1324057, 8.7174611, "Sportcenter Bornheim", "Inheidener Str. 64", "Frankfurt am Main", "Hessen", "60385", "de")
            , "069 9450720\n"));*/

        this.sportCenterGroupHashMap[g.shortId] = g;
        g = {
            id: 2,
            shortId: "tgb",
             slug: "berger-strasse",
            fullText: 'Sportstätten in der Berger Straße 294',
            description: "<b>Berger Str. 294</b><br>\n" +
                "60385 Frankfurt Main<br>\n" +
                "<br>\n" +
                "Tel. 069 4600040   Fax 069 46000440 Mail info@tgbornheim.de<br>\n" +
                "<br>\n" +
                "<br>\n" +
                "Öffnungszeiten der Geschäftstelle:<br>" +
                " Mo-Fr von 8-12 Uhr und 15-20 Uhr<br><br><br>",
            descriptionFixed:
                "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_showbelegplan2&id=17&.pdf')\" class='downloadLink'>Belegplan</a>" +

                "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_sportgroupdaily&id=2&inc1=1&inc2=2&inc3=3&inc4=57&inc5=4&.pdf')\" class='downloadLink'>Hallen Belegung</a>"

            ,
            text: "Berger Str.",
            img: "res/tgb_360.png",
            fullImage: "res/tgb_360.png",
            fullImageLink:"https://my.matterport.com/show/?m=SxCy3nTGqQr"

        };

        this.sportCenterGroupHashMap[g.shortId] = g;
        g = {
            id: 3,
            shortId: "asf",
            slug: "aqua-sports-fun",
            fullText: 'Aqua Sports Fun',
            description: "<b>Gartenbad Fechenheim</b><br>\n" +
                "Konstanzer Str. 16<br>\n" +
                "60386 Frankfurt Main<br>\n" +
                "Tel. 069 42693592    Fax 069 42693594<br>\n" +
                "<br><br>\n" +
                "<b>Panoramabad Bornheim</b><br>\n" +
                "Inheidener Str. 60<br>\n" +
                "60385 Frankfurt Main<br><br><br>",
            descriptionFixed: "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_showbelegplan2&id=15&.pdf')\" class='downloadLink'>Belegplan</a><br>"+
            "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_sportgroupdaily&id=17&inc1=17&inc2=109&inc3=110&inc4=111&.pdf')\" class='downloadLink'>Belegung Panoramabad</a><br>"+
            "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_sportgroupdaily&id=18&inc1=18&inc2=141&inc3=16&inc4=106&.pdf')\" class='downloadLink'>Belegung Gartenbad</a>",
            text: "Aqua Sports Fun",
            img: "res/main_asf.png",
            fullImage: "res/full_asf.png",
            fullImageLink:"https://my.matterport.com/show/?m=Q1SMZrR1Q2S"
        };

        this.sportCenterGroupHashMap[g.shortId] = g;

        g = {
            id: 4,
            slug: "fitness-studios",
            shortId: "studio",
            fullText: 'Fitness Studios',
            text: "Fitness Studio",
            img: "res/main_studio.png",
            fullImage: "res/full_studio.png"
        };

        this.sportCenterGroupHashMap[g.shortId] = g;
        g = {
            id: 1000,
            fullText: 'Externe Sportstätten',
            slug: "externe-sportstaetten",
            shortId: "extern",
            description: "<b>Diverse externe Sportstätten</b><br>\n" +
                "<br>\n" +
                "Tel. 069 4600040   Fax 069 46000440 Mail info@tgbornheim.de<br>\n" +
                "<br>\n" +
                "<br>\n" +
                "Öffnungszeiten der Geschäftstelle:<br>" +
                " Mo-Fr von 8-12 Uhr und 15-20 Uhr<br><br><br>",
            descriptionFixed: "<a target='_blank' onClick=\"showIframe('"+SettingsService.getMainPageUrl()+"index.php?page=pdf_showbelegplan2&id=18&.pdf')\" class='downloadLink'>Belegplan</a>",
            text: "Externe Sportstätten",
            img: "res/main_extern.png",
            fullImage: "res/full_extern.png"
        };

        this.sportCenterGroupHashMap[g.shortId] = g;
    }

    initSportAngebotMappings(sportData) {

        let sportangebotmapping = sportData["sportangebotmapping"];
        if (sportangebotmapping) {
            sportangebotmapping.forEach((mapping) => {

                let sportid = mapping["sportid"];

                let sport = this.sportArtById[sportid];
                if (sport != null) {
                    sport.angebote.push(mapping["angebotid"]);
                    sport.angeboteHash[mapping["angebotid"]] = true;
                }
            });
        }
        let sportangebotmappingcustom = sportData["sportangebotmappingcustom"];
        if (sportangebotmappingcustom != null) {
            sportangebotmappingcustom.forEach((mapping) => {
                let sport = this.sportArtById[mapping["sportid"]];
                if (sport) {
                    sport.angebote.push(mapping["angebotid"]);
                    sport.angeboteHash[mapping["angebotid"]] = true;
                }
            });
        }

    }

    findRelatedSportCentersForGroup(group) {

        var results = [];
        this.sportCenterList.forEach((center) => {
            if (center.sporcenterGroupId === group.id) {
                results.push(center);
            }
        });
        return results;
    }

    findRelatedSportCentersForTermine(termine) {

        var results = [];

        var sportcenterHash = {};

        termine.forEach((termin) => {
            sportcenterHash[termin.sportcenter.id] = true;
        });
        this.sportCenterList.forEach((center) => {
            if (sportcenterHash[center.id]) {
                results.push(center);
            }
        });
        return results;
    }

    findSportAngeboteByTermine(termine) {

        var results = [];

        var angebote = {};

        termine.forEach((termin) => {

            termin.sportArt.angebote.forEach((angebot) => {
                angebote[angebot] = true;
            });
        });

        this.angebotList.forEach(function (angebot) {
            if (angebote[angebot.id]) {
                results.push(angebot);
            }
        });
        return results;
    }

    buildTermineTimeTable(termine) {


        var lastName = "";

        var allRows = [];

        var currentRows = [];

        var last = null;
        termine.forEach((t) => {

            if (last && last.id == t.id) {
                console.error("the same ", last, t);
            }
            last = t;
            var isNewRow = false;
            if (lastName !== t.sportArtName) {
                isNewRow = true;
                t.isHeader = true;
                currentRows = [];
            }
            lastName = t.sportArtName;
            if (isNewRow) {
                let row = {id: this.rowIds++};

                if (row.id == 246 || row.id == 247) {
                    console.log("HIER1", row, t, currentRows);
                }
                row.termin = t;
                allRows.push(row);
                currentRows.push(row);
            }
            var found = false;
            currentRows.forEach((row) => {
                if (!found && !row[t.day]) {
                    row[t.day] = t;
                    found = true;
                } else {
                    if (row.id == 246 || row.id == 247) {
                        console.log("HIER3", found, row, t);
                    }
                }
            });
            if (!found) {
                let row = {id: this.rowIds++};

                if (row.id == 246 || row.id == 247) {
                    console.log("HIER2", row, t, currentRows);
                }
                allRows.push(row);
                currentRows.push(row);
                row.termin = t;
                row[t.day] = t;
            }

        });
        return allRows;
    }

    buildTermineTree(termine) {


        var lastName = "";

        var uls = [];

        var currentRow;
        termine.forEach((t) => {

            var isNewRow = false;
            if (lastName !== t.sportArtName) {
                isNewRow = true;
                t.isHeader = true;
            }
            lastName = t.sportArtName;
            if (isNewRow) {
                let row = {id: this.rowIds++, termine: []};

                row.termin = t;
                currentRow = row;
                uls.push(row);
            }
            if (currentRow) {
                currentRow.termine.push(t);
            }
        });
        console.log("uls", uls);
        return uls;
    }

    categorizeByAngebot(termine) {
        var results = [];

        var angebote = {};

        termine.forEach((termin) => {


            termin.sportArt.angebote.forEach((angebot) => {
                if (!angebote[angebot]) {
                    angebote[angebot] = {
                        termine: [],
                        termineHash: {}
                    };
                }
                if (angebote[angebot].termineHash[termin.id]) {

                    //console.error("already added termin", termin, angebot)
                } else {
                    angebote[angebot].termineHash[termin.id] = termin;
                    angebote[angebot].termine.push(termin)
                }
            });
        });

        this.angebotList.forEach((angebot) => {
            if (angebote[angebot.id]) {
                let a = angebote[angebot.id];
                results.push({
                    name: angebot.name,
                    img: angebot.img,
                    termine: a.termine,
                    originalTermine: termine,
                    tree: this.buildTermineTree(a.termine),
                    table: this.buildTermineTimeTable(a.termine)
                });
            }
        });
        return results;
    }

    categorizeByOneBigAngebot(termine, name, img) {
        var results = [];


        results.push({
            name: name,
            img: img,
            termine: termine,
            originalTermine: termine,
            tree: this.buildTermineTree(termine),
            table: this.buildTermineTimeTable(termine)
        });
        return results;
    }

    findTerminByFullQueryAndSetCurrentFilter(option) {
        let options = Object.assign({}, {
            day: -1,
            fromTime: -1,
            toTime: -1,
            sportCenterGroupFilter: -1,
            sportArtFilter: -1,
            sportArtGroupFilter: -1,
            sportCenterFilter: -1,
            currentSearch: null,
            includeHeaders: false
        }, option);
        let day = options.day;
        let fromTime = options.fromTime;
        let toTime = options.toTime;
        let sportCenterGroupFilter = options.sportCenterGroupFilter;
        let sportArtFilter = options.sportArtFilter;
        let sportArtGroupFilter = options.sportArtGroupFilter;
        let sportCenterFilter = options.sportCenterFilter;
        let currentSearch = options.currentSearch;
        let includeHeaders = options.includeHeaders;
        let results = [];


        var textSearch = null;
        if (currentSearch) {
            currentSearch = currentSearch.toLowerCase().trim();

            var sb = "";
            for (let i = 0; i < currentSearch.length; i++) {
                if (i === currentSearch.length - 1) {
                    sb += currentSearch.charAt(i);
                }
                if (currentSearch.charAt(i) === ' ' || currentSearch.charAt(i) === ',' || i === currentSearch.length - 1) {
                    let ss = sb.trim();
                    if (ss.length > 0) {
                        if (textSearch == null) {
                            textSearch = [];
                        }
                        textSearch.push({text: ss});
                    }
                    sb = "";
                } else {
                    sb += currentSearch.charAt(i);
                }

            }
        }
        if (textSearch) {
            console.log("textsearch count " + textSearch.length);
            textSearch.forEach((search) => {
                console.log("textsearch " + search.text);
            });
        }

        this.sportArtList.forEach((sportArt) => {

            //logInfo("search day 1");
            if (sportArtFilter === -1 || sportArt.id === sportArtFilter) {
                let name = sportArt.name;
                //  logInfo("search day 2 " + name);


                var textSportArtIn = false;

                if (textSearch && name) {

                    var allOk = true;
                    textSearch.forEach((searchTextEntry) => {
                        searchTextEntry.containedAlready = false;
                        if (sportArt.nameLower.indexOf(searchTextEntry.text) !== -1) {
                            searchTextEntry.containedAlready = true;
                        } else if (sportArt.descriptionLower && sportArt.descriptionLower.indexOf(currentSearch) !== -1) {
                            searchTextEntry.containedAlready = true;
                        }
                        if (!searchTextEntry.containedAlready) {
                            allOk = false;
                        }
                    });
                    textSportArtIn = allOk;
                }


                var sportGroupOk = true;
                if (sportArtGroupFilter !== -1) {
                    sportGroupOk = sportArt.angeboteHash[sportArtGroupFilter];// ((IAsgularObject) child).getChild(Integer.toString(sportArtGroupFilter)) != null;
                }

                var first = true;
                if (sportGroupOk) {


                    sportArt.termine.forEach((termin) => {
                        if (day === -1 || (day === termin.day && ((fromTime === -1) || (fromTime <= termin.start && toTime >= termin.start)))
                        /*|| fromTime >= termin.getStart() && fromTime <= termin.getEnde()*/) {


                            if ((sportCenterFilter === -1 || sportCenterFilter === termin.sportcenter.id) &&
                                (sportCenterGroupFilter === -1 || termin.sportcenter.sporcenterGroupId === sportCenterGroupFilter)) {


                                var allowed;

                                //   logInfo("search day 4 " + currentSearch);
                                if (textSearch != null) {

                                    allowed = textSportArtIn;
                                    if (!textSportArtIn) {
                                        var allOk = true;
                                        textSearch.forEach((searchTextEntry) => {
                                            if (!searchTextEntry.containedAlready) {
                                                if (!(termin.sportcenter.nameLower.indexOf(searchTextEntry.text) !== -1)
                                                    && !(termin.trainer && termin.trainer.nameLower.indexOf(searchTextEntry.text) !== -1)) {
                                                    allOk = false;
                                                }
                                            }
                                        });
                                        allowed = allOk;
                                    }
                                } else {
                                    allowed = true;
                                }

                                if (allowed) {

                                    if (first) {
                                        first = false;

                                        //displayHeader.setTimeSort(getNameLower());
                                        //displayHeader.setName(name);
                                        //results.add(sportArt.getOrCreateTerminDisplayHeader());

                                        if (includeHeaders) {
                                            results.push({
                                                header: true,
                                                name: sportArt.nameLower,
                                                timeSort: sportArt.nameLower
                                            })
                                        }
                                    }


                                    results.push(termin);
                                }

                            }

                        }
                    });

                }
            }
        });
        results.sort((o1, o2) => {
            return o1.timeSort.localeCompare(o2.timeSort);
        });
        return results;
    }

    onChange(func) {
        var found = false;
        this.changeEvent.forEach((ele) => {
            if (ele === func) {
                found = true;
            }
        });
        if (!found) {
            this.changeEvent.push(func);
        }
        if (this.isLoaded) {
            func();
        }
    };


    removeOnChange(func) {
        let idx = this.changeEvent.indexOf(func);
        if (idx >= 0) {
            this.changeEvent.splice(idx, 1);
        }
    }

}

export default new SportService();
