import React from "react";
import LayoutService from "../Services/LayoutService";

export default class SRow extends React.Component {

    render() {
        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'flex-start'}}>
                {this.props.children}
            </div>
        )
    }

}
