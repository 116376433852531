import React from "react";
import PageDataService from "../../DataSources/PageDataService"
import LayoutService from "../../Services/LayoutService";
import SMainPageLabel from "../../StyleComponents/SMainPageLabel";

export default class VeranstaltungsSingleDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {

        PageDataService.onChange(() => {
            this.didReceiveData = true;
            this.updateView(this.props);
        });

    }

    componentWillReceiveProps(nextProps) {

        //console.log('nextProps',nextProps);
        this.updateView(nextProps);
    }

    updateView(props) {
        if (this.didReceiveData) {
            this.state.description = PageDataService.getVeranstaltungPage(props.match.params.veranstaltungsId);
            this.state.loading = false;
            this.setState(this.state);

        }
    }

    render() {
        return (
            <div style={{
                width: LayoutService.fullContentWidth, justifyContent: 'space-between',
                alignItems: 'flex-start',
                display: 'flex', flexDirection: 'column'
            }}>
                <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>

                    <SMainPageLabel text={this.state.title}/>
                    <div className="bigshadow description"
                         style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50}}
                         >

                        <div dangerouslySetInnerHTML={{__html: this.state.description}}></div>
                        <div style={{clear:"both"}}></div>


                    </div>

                </div>

            </div>
        )
    }
}
