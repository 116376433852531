//require('es6-promise').polyfill();
//require('isomorphic-fetch');

class CacheableDataSource {

    loadCachedJsonData(url,onLoad) {

        try {
            let cachedValue = localStorage.getItem('_cached_' + url);

            if (cachedValue && cachedValue.indexOf("{") !== -1) {
                try {
                    cachedValue = cachedValue.replaceAll(String.fromCharCode(32), ' ');
                    cachedValue = cachedValue.replaceAll(String.fromCharCode(9), ' ');
                    onLoad(JSON.parse(cachedValue), true);
                } catch (e) {
                    console.error("could not load cached value", e, cachedValue);
                }
            }
        }catch (e) {
            onLoad(null,null,e);
        }
        fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                try {
                    localStorage.setItem('_cached_' + url, JSON.stringify(responseJson));
                }catch (e) {
                    console.error('could not store cache',e);
                    localStorage.removeItem('_cached_' + url);
                }
                onLoad(responseJson,false);
            })
            .catch((error) => {
                console.error(error);
                onLoad(null,null,error);
            });
    }
}

export default new CacheableDataSource();
