import React from "react";
import LayoutService from "../../Services/LayoutService";
import SMainPageLabel from "../../StyleComponents/SMainPageLabel";

import {store, view} from '@risingstack/react-easy-state';
import LoginDisplay from "./LoginDisplay";

import Globals from "./Globals";
import SettingsService from "../../DataSources/SettingsService";
import moment from "moment";
import ReactModal from "react-modal";

import {isHoliday} from 'feiertagejs';

import SportService from "../../DataSources/SportService";


let didFetchAdminData = false;

let didInitSportListener = false;
export default view((props) => {

    const reservationData = store({
        timeData: null,
        selectedPlaceId: null,
        placeArray: [],
        sportLoaded: false,
        bookingLoaded: false,
        placeData: {
            /*"-1": {
                text: "Indoorspielen in der Halle"
            },
            "-2": {
                text: "Indoorspielen im Gym 2"
            },
            "-3": {
                text: "Indoorspielen im Gym 3"
            },
            "-4": {
                text: "Studio Falltorstr."
            },
            "-5": {
                text: "Studio Sportcenter"
            },
            "-6": {
                text: "Tischtennis Gym 1"
            },
            "-7": {
                text: "Badminton Halle SCB"
            },"-8": {
                text: "Kletterwand SCB"
            },"-9": {
                text: "Tischtennis Saalbau"
            }*/
        },
        currentReservation: null
    });

    const updateDataAfterLoad = () => {
        if (!reservationData.sportLoaded || !reservationData.bookingLoaded) {
            return;
        }

        reservationData.placeArray.forEach((place) => {
            if (place.connected_tg_sport) {
//                    dayId: day.format('DD/MM/YYYY'),
                let termine = SportService.findTerminByFullQueryAndSetCurrentFilter({
                    sportArtFilter: place.connected_tg_sport
                });


                // dayId + '_' + hourId+'_-424242'
                let possibleTermine = [];
                for (let d = -2; d < 14; d++) {
                    let day = moment().add(d, 'days').hours(0).minutes(0).seconds(0);
                    let weekDay = day.isoWeekday();

                    // .dayMoment.format('YYYY-MM-DD') + ' ' + termin.hour + ':00'
                    termine.forEach((t) => {
                        if (t.day + 1 === weekDay) {
                            let hour = Math.trunc(t.start / 60);
                            let min = t.start-hour*60;
                            let sportCenterId = -3;
                            if (t.sportcenter) {
                                sportCenterId = t.sportcenter.id;
                            }
                            let terminId = day.format('DD/MM/YYYY') + '_' + hour + '_' + sportCenterId;//  'termin_'+place.sport_id+'_'+(terminId++);
                            possibleTermine.push({

                                dayId: day.format('DD/MM/YYYY'),
                                book_termin: day.format('YYYY-MM-DD') + ' ' + hour + ':'+min,
                                hour: hour,
                                key: terminId,
                                room_id: sportCenterId,
                                terminId: terminId,
                                hourNum: hour,
                                dayMoment: moment(day),
                                termin: t
                            });
                        }
                    });
                    //terminHash[dayOb.termine[i].terminId] = dayOb.termine[i];


                }
                place.tg_sport_termine = possibleTermine;
                /*termine.forEach((t)=>{
                   t.wd = isoWeekday();
                });*/

                console.log("connected_tg_sport", possibleTermine, place);
            }
        });
        //this.state.sportcenters = SportService.findRelatedSportCentersForTermine(this.state.termine);

        //this.state.categories = SportService.categorizeByAngebot(this.state.termine);

        //this.state.angebote = SportService.findSportAngeboteByTermine(this.state.termine);

    };
    if (!didInitSportListener) {
        didInitSportListener = true;
        SportService.onChange(() => {
            reservationData.sportLoaded = true;
            updateDataAfterLoad();
        });
    }

    let specialPlaceImages = {
        '-5': 'res/reservierung/StudioScb.jpg',
        '-4': 'res/reservierung/StudioFalltor.jpg'
    }

    const calcTimes = () => {

        let allowedDays = {
            '-1': moment('01/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-2': moment('01/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-3': moment('05/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-4': moment('08/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-5': moment('08/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-6': moment('27/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-7': moment('27/03/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-8': moment('06/04/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
            '-9': moment('25/05/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0),
        }

        const firstHour = 7;
        const lastHour = 21;
        let getFirstHourAndLastHourByPlaceAndDay = (placeId, dayOfWeek, dayMoment) => {
            if (placeId !== '-4' && placeId !== '-5') {

                if (dayMoment.isAfter(moment('04/06/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0))) {
                    return {
                        firstHour: 0,
                        lastHour: 0
                    };
                }
            }
            if (placeId === '-9') {
                if (dayOfWeek === 2 || dayOfWeek === 3) {
                    return {
                        firstHour: 17,
                        lastHour: 21
                    };
                }
                return {
                    firstHour: 0,
                    lastHour: 0
                };
            }
            if (placeId === '-8') {
                if (dayOfWeek <= 1) {
                    return {
                        firstHour: 22,
                        lastHour: 22
                    };
                } else if (dayOfWeek <= 3) {
                    return {
                        firstHour: 18,
                        lastHour: 19
                    };
                } else if (dayOfWeek <= 5) {
                    return {
                        firstHour: 16,
                        lastHour: 19
                    };
                } else if (dayOfWeek <= 7) {
                    return {
                        firstHour: 10,
                        lastHour: 13
                    };
                }
                return {
                    firstHour: 7,
                    lastHour: 21
                };
            }
            if (placeId === '-7' || placeId === '-5') {
                if (isHoliday(moment(dayMoment).toDate(), 'HE')) {
                    return {
                        firstHour: 9,
                        lastHour: 19
                    };
                }
                if (dayOfWeek >= 6) {
                    return {
                        firstHour: 9,
                        lastHour: 19
                    };
                }
                return {
                    firstHour: 7,
                    lastHour: 21
                };
            }
            if (placeId === '-4') {

                if (dayMoment.isAfter(moment('07/06/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0))) {
                    if (isHoliday(moment(dayMoment).toDate(), 'HE') || dayOfWeek >= 6) {
                        return {
                            firstHour: 10,
                            lastHour: 17
                        };
                    }
                    return {
                        firstHour: 10,
                        lastHour: 21
                    };
                }

                /*if (dayOfWeek >= 6) {
                    return {
                        firstHour: 10,
                        lastHour: 17
                    };
                }*/
                return {
                    firstHour: 10,
                    lastHour: 17
                };
            }
            return {
                firstHour: 9,
                lastHour: 18
            };
        }

        let day = moment().add(-2, 'days').hours(0).minutes(0).seconds(0);

        let terminHash = {};
        let dayHash = {};
        let weeks = [];

        let dayNames = {
            1: 'Montag',
            2: 'Dienstag',
            3: 'Mittwoch',
            4: 'Donnerstag',
            5: 'Freitag',
            6: 'Samstag',
            7: 'Sonntag',
        }
        let isDayBookable = (day, placeId) => {
            if (day.isSameOrAfter(allowedDays[placeId])) {
                if (placeId === '-4' || placeId === '-5') {
                    if (day.isAfter(moment('24/05/2021', 'DD/MM/YYYY').hours(0).minutes(0).seconds(0))) {

                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        };
        for (let w = 0; w < 2; w++) {
            let week = {
                weekNum: w,
                days: [],
                hours: []
            };
            for (let i = 7; i <= 21; i++) {
                week.hours.push(i);
            }
            for (let d = 0; d < 7; d++) {
                day = moment(day).add(1, 'days');

                const dayOb = {
                    dayId: day.format('DD/MM/YYYY'),
                    dayText: dayNames[day.isoWeekday()] + ' ' + day.format('DD.MM'),
                    date: day,
                    active: {},
                    dayNum: d * w,
                    dayOfWeek: moment(day).isoWeekday(),
                    termine: {}
                };
                reservationData.placeArray.forEach((place) => {
                    dayOb.active[place.sport_id] = isDayBookable(day, place.sport_id);
                });
                dayHash[dayOb.dayId] = dayOb;
                for (let i = firstHour; i <= lastHour; i++) {

                    let activeOb = {};
                    reservationData.placeArray.forEach((place) => {
                        let placeId = place.sport_id;
                        let t = getFirstHourAndLastHourByPlaceAndDay(placeId, dayOb.dayOfWeek, moment(day));

                        activeOb[placeId] = isDayBookable(day, placeId) &&
                            i >= t.firstHour && i <= t.lastHour;
                    });
                    dayOb.termine[i] = {
                        active: activeOb,
                        terminId: dayOb.dayId + "_" + i + '_-424242',
                        hourNum: i,
                    }
                    terminHash[dayOb.termine[i].terminId] = dayOb.termine[i];

                }
                week.days.push(dayOb);
            }
            weeks.push(week);
        }


        return {
            terminHash: terminHash,
            dayHash: dayHash,
            weeks: weeks
        };
    };

    if (!didFetchAdminData) {
        didFetchAdminData = true;
        fetch(SettingsService.getDataUrlNew() + "?service=welcome&=" + (new Date().getTime()))
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                if (responseJson.admin) {
                    reservationData.adminMode = responseJson.admin;
                } else {
                    reservationData.adminMode = 'none';
                }
                reservationData.bookingLoaded = true;
                if (responseJson.bookingSettings) {
                    responseJson.bookingSettings.forEach((bs) => {

                        bs.sport_id = bs.sport_id + '';
                        bs.text = bs.display_name;
                        bs.imgSrc = specialPlaceImages[bs.sport_id];
                        reservationData.placeArray.push(bs);
                        reservationData.placeData[bs.sport_id + ''] = bs;
                    });
                }
                reservationData.timeData = calcTimes();
                updateDataAfterLoad();
            });
    }

    reservationData.displayOnly = props.displayOnly;
    let myBarcode = Globals.barcode;
    if (props.displayOnly) {
        myBarcode = 'displayOnly';
    }
    const updateBookingState = () => {
        let barcode = reservationData.lastBarcode;
        let geburtsDatum = Globals.geburtsDatum;
        fetch(SettingsService.getDataUrlNew() + "?service=terminbuchungen_overview_v2&geburtstag=" + geburtsDatum + "&barcode=" + barcode + "&time=" + (new Date().getTime()))
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {


                if (responseJson.admin) {
                    reservationData.adminMode = responseJson.admin;
                } else {
                    reservationData.adminMode = 'none';
                }

                reservationData.places = {};
                reservationData.currentReservation = null;
                if (responseJson.booked_entry) {
                    responseJson.booked_entry.sport_id = responseJson.booked_entry.sport_id + '';
                    const sportId = responseJson.booked_entry.sport_id + '';
                    if (sportId) {
                        let entry = {};
                        if (reservationData.placeData[sportId]) {
                            entry.placeText = reservationData.placeData[sportId].text;
                            console.log("responseJson.booked_entry22", reservationData.placeData, sportId, responseJson.booked_entry, entry);
                        }
                        console.log("booked_time booked",entry);
                        entry.booking_timeTxt = responseJson.booked_entry.booking_time;
                        entry.booking_time = moment(responseJson.booked_entry.booking_time, 'YYYY-MM-DD HH:mm');
                        entry.booking_test_id =entry.booking_time.format('YYYY-MM-DD HH')+'_'+responseJson.booked_entry.room_id;
                        entry.sport_id = sportId;
                        reservationData.currentReservation = entry;
                        console.log("responseJson.booked_entry", reservationData.placeData, sportId, responseJson.booked_entry, entry);
                    }
                }
                if (responseJson.array) {
                    responseJson.array.forEach((entry) => {

                        if (entry.sport_id) {
                            entry.sport_id = entry.sport_id+'';
                            if (!reservationData.places[entry.sport_id]) {
                                reservationData.places[entry.sport_id] = {
                                    bookingTimes: {}
                                };
                            }
                            const place = reservationData.places[entry.sport_id];

                            entry.booking_timeTxt = entry.booking_time;
                            console.log("booked_time",entry);
                            entry.booking_time = moment(entry.booking_time, 'YYYY-MM-DD HH:mm');
                            entry.dayId = entry.booking_time.format('DD/MM/YYYY');
                            entry.hourNum = entry.booking_time.hour();
                            entry.booking_test_id =entry.booking_time.format('YYYY-MM-DD HH')+'_'+entry.room_id;
                            entry.terminId = entry.dayId + '_' + entry.hourNum + '_' + entry.room_id;
                            entry.placeId = entry.sport_id;
                            entry.placeText = "unknown";

                            if (entry.filled === true) {
                                entry.barcode = 'h';
                            } else if (entry.filled === false) {
                                entry.barcode = 'empty';
                            } else {
                                entry.barcode =  entry.filled;
                            }
                            if (reservationData.currentReservation &&
                                (reservationData.currentReservation.booking_test_id === entry.booking_test_id && entry.sport_id === reservationData.currentReservation.sport_id) ) {
                                entry.barcode = myBarcode;
                            }
                            if (reservationData.placeData[entry.sport_id]) {
                                entry.placeText = reservationData.placeData[entry.sport_id].text;
                            }

                            /*if (entry.barcode === myBarcode) {
                                if (entry.booking_time.isAfter(moment())) {
                                    reservationData.currentReservation = entry;
                                }
                            }*/
                            place.bookingTimes[entry.terminId] = entry;

                        }
                    });
                }
                console.log("reservationData", reservationData);

            })
            .catch((error) => {
                console.error('error in loading reservation data', error);
            });
    }
    const doReservation = (deleteWish, termin, room) => {
        if (!room) {
            room = -424242;
        }
        let barcode = reservationData.lastBarcode;
        let geburtsDatum = Globals.geburtsDatum;
        fetch(SettingsService.getDataUrlNew() + "?service=terminbuchungen_book_v2&geburtstag=" + geburtsDatum + "&barcode=" + barcode + "&delete=" + deleteWish + "&termin=" + termin + "&sport=" + reservationData.selectedPlaceId + "&room=" + room + "&time=" + (new Date().getTime()))
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                console.log("doReservationResponse", responseJson);
                if (responseJson.special_message) {
                    reservationData.showError = true;
                    reservationData.errorText = responseJson.special_message;
                    updateBookingState();
                }
                if (responseJson.error) {
                    if (responseJson.abteilung) {
                        reservationData.showError = true;
                        let abteilungsText = responseJson.abteilung;
                        if (abteilungsText === '25') {
                            abteilungsText = "Fitness Studio";
                        }
                        reservationData.errorText = 'Sie müssen Mitglied der Abteilung ' + abteilungsText + ' sein';
                    }
                }
                updateBookingState();
            })
            .catch((error) => {
                console.error('error in loading reservation data', error);
            });
    };

    if (reservationData.lastBarcode !== myBarcode && myBarcode) {

        reservationData.lastBarcode = myBarcode;
        updateBookingState();
    }

    let layoutState = {};
    layoutState.leftWidth = 130;

    let width = LayoutService.fullContentWidth - 60;
    layoutState.dayWidth = (width - 20) / 8;
    layoutState.leftWidth = layoutState.dayWidth + 20;


    const terminStateSport = (termin) => {
        const placeId = reservationData.selectedPlaceId;
        const key = termin.terminId;
        let bookingData = null;
        if (reservationData.places && reservationData.places[placeId]) {
            bookingData = reservationData.places[placeId].bookingTimes[key];
        }

        let className = "";
        let displayText = "";
        let canBook = false;
        if (bookingData) {
            if (bookingData.barcode === myBarcode) {
                console.log("gebucht",bookingData.barcode,myBarcode);
                className = "gebucht";
                displayText = "Gebucht!"
            } else {
                if (bookingData.barcode === 'empty') {
                    canBook = true;
                    className = "buchbar";
                    displayText = "Buchen";
                } else {
                    className = "ausgebucht";
                    if (bookingData.barcode === 'h') {
                        displayText = "ausgebucht";
                    } else {
                        if (bookingData.filledBlocked === false) {
                            canBook = true;
                            className = "buchbar";
                            displayText = bookingData.barcode;
                        } else {
                            displayText = bookingData.barcode;
                        }
                    }
                }
            }
        } else {
            canBook = true;
            className = "buchbar";
            displayText = "Buchen";
        }
        return {
            canBook: canBook,
            displayText: displayText,
            className: className,
            termin: null,
            booking: bookingData
        }
    };
    const terminState = (dayId, hourId) => {
        const placeId = reservationData.selectedPlaceId;
        const key = dayId + '_' + hourId + '_-424242';
        let bookingData = null;
        if (reservationData.places && reservationData.places[placeId]) {
            bookingData = reservationData.places[placeId].bookingTimes[key];
        }
        const timeData = reservationData.timeData.terminHash[key];
        let className = "";
        let displayText = "";
        let canBook = false;
        if (timeData.active[placeId]) {
            if (bookingData) {
                if (bookingData.barcode === myBarcode) {
                    className = "gebucht";
                    displayText = "Für Sie gebucht!"
                } else {
                    if (bookingData.barcode === 'empty') {
                        canBook = true;
                        className = "buchbar";
                        displayText = "Buchen";
                    } else {
                        className = "ausgebucht";
                        if (bookingData.barcode === 'h') {
                            displayText = "ausgebucht";
                        } else {
                            if (bookingData.filledBlocked === false) {
                                canBook = true;
                                className = "buchbar";
                                displayText = bookingData.barcode;
                            } else {
                                displayText = bookingData.barcode;
                            }
                        }
                    }
                }
            } else {
                canBook = true;
                className = "buchbar";
                displayText = "Buchen";
            }
        }
        return {
            canBook: canBook,
            displayText: displayText,
            className: className,
            termin: timeData,
            booking: bookingData
        }
    };
    let innerStuff = <div style={{
        width: LayoutService.fullContentWidth, justifyContent: 'space-between',
        alignItems: 'flex-start',
        display: 'flex', flexDirection: 'column'
    }}>
        <div className="fullcon" style={{width: LayoutService.fullContentWidth}}>


            <SMainPageLabel text="Reservierung"/>
            <ReactModal
                isOpen={reservationData.showError}
                contentLabel="Close"
                onRequestClose={() => {
                    reservationData.showError = false;
                }}
                className="Modal reserveration-errormodal"
                overlayClassName="Overlay"

            >
                <h3 className="reservation-error-text">{reservationData.errorText}</h3>
            </ReactModal>
            <div className="bigshadow"
                 style={{marginTop: 20, backgroundColor: '#ffffff', padding: 20, minHeight: 50, overflow: "auto"}}>

                {
                    reservationData.displayOnly &&
                    <div>
                        Display Only Modus!
                    </div>
                }
                {
                    !reservationData.displayOnly &&
                    <div>
                        {
                            reservationData.currentReservation &&
                            <div>
                                <div className="reservation-important-label">Sie haben eine aktuelle
                                    Reservierung mit dem Barcode {myBarcode}</div>
                                <div className="reservation-reservation-panel">
                                    <div>
                                        {reservationData.currentReservation.booking_time.format('DD.MM.YYYY HH:mm')}
                                        Uhr {reservationData.placeData[reservationData.currentReservation.sport_id] && reservationData.placeData[reservationData.currentReservation.sport_id].text}
                                    </div>

                                    <button className="btn reservation-delete-button" onClick={() => {
                                        doReservation(true);
                                    }}>
                                        Reservierung löschen
                                    </button>
                                </div>
                            </div>
                        }

                        {
                            <div className="reservation-reservation-panel">
                                Sie können mit ihrem Barcode {myBarcode} genau einen Termin in der Zukunft
                                buchen.<br/>
                                Sobald der Termin vorbei ist können Sie den nächsten Buchen.<br/>
                                Es werden keine Bestätigungsmails versendet.<br/>
                                <span style={{display: "none"}} className="reservation-error-text">Bitte bedenken Sie, dass Sie die Fitness Studios nur als doppelt Geimpfte/r, Genesene/r oder mit einem negativen Test besuchen dürfen. Ein entsprechender Nachweis wird von unserem Counter vor Ort kontrolliert.</span>
                            </div>
                        }
                    </div>
                }
                {
                    <div className="reservation-select-place">
                        <div className="reserveration-select-title">
                            Sportprogramm das reserviert werden soll auswählen:
                        </div>
                        <div className="reservation-image-table">
                            {
                                reservationData.placeArray.map((place) =>
                                    <div
                                        onClick={() => {
                                            reservationData.selectedPlaceId = place.sport_id
                                        }}
                                        className={"reservation-image-entry " + ((reservationData.selectedPlaceId === place.sport_id) ? "selected" : "")}>
                                        {
                                            place.imgSrc &&
                                            <div className="reservation-image-img">
                                                <img src={place.imgSrc}/>
                                            </div>
                                        }
                                        {
                                            !place.imgSrc &&
                                            <div className="reservation-image-img">
                                                <div className="text-img">
                                                    <span>
                                                    {place.display_name}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        <div className="reservation-image-title">{place.display_name}</div>
                                    </div>
                                )
                            }

                        </div>
                        <select className="reservation-place-select" value={reservationData.selectedPlaceId}
                                onChange={(event) => {
                                    reservationData.selectedPlaceId = event.target.value;
                                }}>
                            <option value="">Keine Auswahl</option>
                            {
                                reservationData.placeArray.map((place) =>
                                    <option value={place.sport_id}>{place.display_name}</option>
                                )
                            }
                        </select>
                    </div>
                }
                {
                    ((reservationData.selectedPlaceId === '-4' || reservationData.selectedPlaceId === '-5') && false) &&
                    <div className="reservation-error-big">
                    </div>
                }
                {
                    reservationData.selectedPlaceId && reservationData.timeData &&
                    reservationData.placeData[reservationData.selectedPlaceId].connected_tg_sport &&
                    reservationData.placeData[reservationData.selectedPlaceId].tg_sport_termine &&
                    <table className="belegplanTable reservation-table">
                        <tbody>
                        {
                            reservationData.placeData[reservationData.selectedPlaceId].tg_sport_termine.map((termin) =>
                                <tr key={termin.key}>
                                    <td className="firstCol">
                                        {termin.dayMoment.format("DD.MM.")}
                                    </td>
                                    <td>
                                        {termin.termin.dayText}
                                    </td>
                                    <td>
                                        {termin.termin.vonBis}
                                    </td>
                                    <td>
                                        {termin.termin.trainerName}
                                    </td>
                                    <td>
                                        {termin.termin.sportcenter && termin.termin.sportcenter.name}
                                    </td>
                                    <td className={terminStateSport(termin).className}
                                        key={termin.dayId} style={{
                                        width: layoutState.dayWidth,
                                        maxWidth: layoutState.dayWidth
                                    }}
                                        onClick={() => {
                                            if (!reservationData.displayOnly) {
                                                if (terminStateSport(termin).canBook) {
                                                        doReservation(false, termin.book_termin, termin.room_id);
                                                }
                                            }
                                        }}
                                    >
                                        {
                                            <div>
                                                {terminStateSport(termin).displayText}
                                            </div>
                                        }
                                    </td>
                                </tr>)
                        }
                        </tbody>
                    </table>
                }
                {
                    reservationData.selectedPlaceId && reservationData.timeData && !reservationData.placeData[reservationData.selectedPlaceId].connected_tg_sport &&
                    reservationData.timeData.weeks.map((week) =>
                        <div key={week.weekNum}>
                            <table className="belegplanTable reservation-table">
                                <thead>
                                <tr>
                                    <td className="firstCol"
                                        style={{width: layoutState.leftWidth}}>Sportart
                                    </td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[0].dayText}</td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[1].dayText}</td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[2].dayText}</td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[3].dayText}</td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[4].dayText}</td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[5].dayText}</td>
                                    <td style={{width: layoutState.dayWidth}}>{week.days[6].dayText}</td>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    week.hours.map((hour) =>
                                        <tr key={hour}>
                                            <td className="firstCol" style={{
                                                width: layoutState.leftWidth,
                                                maxWidth: layoutState.leftWidth
                                            }}>
                                                {hour} Uhr
                                            </td>
                                            {
                                                week.days.map((day) =>

                                                    <td className={terminState(day.dayId, hour).className}
                                                        key={day.dayId} style={{
                                                        width: layoutState.dayWidth,
                                                        maxWidth: layoutState.dayWidth
                                                    }}
                                                        onClick={() => {
                                                            if (!reservationData.displayOnly) {
                                                                if (terminState(day.dayId, hour).canBook) {
                                                                    doReservation(false, day.date.format('YYYY-MM-DD') + ' ' + hour + ':00');
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            terminState(day.dayId, hour).termin.active[reservationData.selectedPlaceId] &&
                                                            <div>
                                                                {terminState(day.dayId, hour).displayText}
                                                            </div>
                                                        }
                                                    </td>)
                                            }
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    )
                }


            </div>
        </div>

    </div>;

    let pageContent;
    if (reservationData.displayOnly) {
        pageContent = innerStuff;
    } else {
        pageContent = (

            <LoginDisplay displayName="Reservierung">
                {innerStuff}
            </LoginDisplay>
        );
    }
    return <div>
        {
            reservationData.adminMode === 'base' &&
            <div className="reservation-admin-mode-header">
                <a href="#reservierung-admin">Reservierungs-Admin</a>
                |
                <a href="?sport1=-5&sport2=-4&sport3=-10&sport4=-11#reservierung-admin-list">Reservierungs-Liste</a>
            </div>

        }
        {pageContent}
    </div>
});