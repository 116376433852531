import AppSpecific from '../AppSpecific';

class SettingsService {

    static getMainPageUrl(){
        return AppSpecific.mainPageBackendUrl;
    }
    static getKursUrl(){
        return AppSpecific.mainPageBackendUrlV3;
    }
    static getVersionText(){
        return "v4.003";
    }
    static getDataUrlNew(){
        return "https://www.tgbornheim.de/v4/";
        //return "http://cpweb01.tgb.luka.de/";
    }
}

export default SettingsService;
