import {Link} from "react-router-dom";
import VertretungsDataService from "../DataSources/VertretungsDataService";
import vertretungsDataService from "../DataSources/VertretungsDataService";
import React from "react";
import {EnterNumberDisplayAccess} from "./EnterNumberDisplay";

export default (props) => {

    const termin = props.termin;
    const dayWidth = props.dayWidth;

    let permanentId = termin.permanentId;

    let timeChanged = false;

    let ortChanged = false;

    let trainerChanged = false;
    let vertretungsDaten = VertretungsDataService.getVertretungsDatenByPermaId(permanentId);

    let canceled = false;
    let vertretungChanged = false;
    let newTime = "";
    let freitext = undefined;
    let freitextActive = false;
    let ort = undefined;
    let trainer = undefined;
    if (vertretungsDaten && vertretungsDaten.length > 0) {

        let vertretung = vertretungsDaten[0];
        vertretungChanged = true;
        if (vertretung.freitext) {
            freitextActive = true;
            freitext = vertretung.freitext;
        }
        if (vertretung.canceled) {
            canceled = true;
        }
        if (vertretung.uhrzeit) {
            timeChanged = true;
            vertretungChanged = true;
            newTime = "ab " + vertretung.uhrzeit;
        }
        if (vertretung.ort) {
            ort = vertretung.ort;
            vertretungChanged = true;
            ortChanged = true;
        }
        if (vertretung.trainer) {
            trainer = vertretung.trainer;
            vertretungChanged = true;
            trainerChanged = true;
        }
    }
    return <Link
        to={"/sportart/" + termin.sportArt.id}
        onTouchEnd={(ev)=>{
            EnterNumberDisplayAccess.handleTouchEnd(ev,props.termin);
        }}
        onTouchStart={(ev)=>{
            EnterNumberDisplayAccess.handleTouchStart(ev,props.termin);
        }}

        onMouseDown={(ev)=>{
            if(ev.button===2) {
                if (vertretungsDataService.baseAdminAccess) {
                    ev.stopPropagation();
                    ev.preventDefault();
                    EnterNumberDisplayAccess.ActivatePopup(props.termin);
                }
            }
        }}
    >

        <div className={"vonBis" + (canceled ? " canceledText" : "")} style={{
            width: dayWidth,
            maxWidth: dayWidth
        }}>
            {
                canceled &&
                <div className="canceledText">Fällt aus</div>
            }
            {
                freitextActive &&
                <div>{freitext}</div>
            }
            {
                timeChanged &&
                <>
                <span className={"vertretungChanged"}>
                    {newTime}
                </span>
                    <span className={"crossedOut"}>
                    {termin.vonBis}
                </span>
                </>
            }
            {
                !timeChanged && <span>
                    {termin.vonBis}
                </span>
            }
        </div>
        <div className={"sportcenter" + (canceled ? " canceledText" : "")} style={{
            width: dayWidth,
            maxWidth: dayWidth
        }}>
            {
                ortChanged && <span className="vertretungChanged">{ort}</span>
            }
            {
                !ortChanged && <span>
                {termin.sportcenter.name}
                </span>
            }
        </div>
        <div className={"trainer" + (canceled ? " canceledText" : "")} style={{
            width: dayWidth,
            maxWidth: dayWidth
        }}>
            {
                trainerChanged && <span className="vertretungChanged">{trainer}</span>
            }
            {
                !trainerChanged && <span>
                {termin.trainerName}
                </span>
            }
        </div>
    </Link>
}